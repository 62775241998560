<template>
    <div :class="{Header: true, 'app-header': getIsApp()}">
        <div class="Header-back">
            <i class="iconfont icon-page-left" @click="toBack()" v-show="show"></i>
        </div>
        <div class="Header-title">{{headerTitle}}</div>
        <div class="Header-operat">
            <div v-if="menuState&&menuState.show" @click="menuClick()" class="Header-operat_preservation">
                <template v-if="menuState.icon">
                    <i class="iconfont" :class="menuState.icon"></i>
                </template>
                <template v-else>{{menuState.title}}</template>
            </div>
        </div>
    </div>
</template>
<script>
import hybrid from '@/libs/hybrid'
import { mapGetters } from 'vuex'
import { getIsApp } from '@/libs/utils'
export default {
    name: 'Header',
    data() {
        return {
            menuState: '',
            show: false
        }
    },
    computed: {
        ...mapGetters(['headerTitle'])
    },
    created() {
        /* if (this.$route.path.indexOf('login') != -1) {
            this.show = false
        } else if (!this.show) {
            this.show = true
        } */

        this.Global.utils.rightMenu.subscribe(state => {
            this.menuState = { ...state }
        })
        hybrid.on('workBenchNew', () => {
            this.menuClick()
        })
    },
    mounted() {
        this.backShow()
    },
    methods: {
        getIsApp,
        menuClick() {
            let { actions } = this.menuState
            actions.forEach(action => {
                action()
            })
        },

        toBack() {
            this.$router.back(-1)
        },
        backShow() {
            const path = this.$route.path
            if (path.indexOf('/PriceCalc/home') != -1) {
                this.show = true
            } else if (getIsApp() && this.$route.meta.isAppRoot) {
                this.show = false
            } else if (path.indexOf('login') != -1 || path === '/workbench' || path === '/app') {
                this.show = false
            } else if (!this.show) {
                this.show = true
            }
        }
    },
    watch: {
        $route(to, from) {
            if (to) {
                this.backShow()
            }
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
.Header {
    width: 100%;
    .height(44px);
    .line-height(44px);
    text-align: center;
    background: #d0021b;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    display: flex;
    overflow: hidden;
    .padding-left(10px);
    .padding-right(10px);
    .font-size(18px);
    &-title {
        flex: 1;
        .padding-left(4px);
        .padding-right(4px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &-back {
        .width(44px);
        overflow: hidden;
        .iconfont {
            .font-size(28px);
        }
    }
    &-operat {
        .width(44px);
        overflow: hidden;
        text-overflow: ellipsis;
        .iconfont {
            .font-size(28px);
        }
    }
}

.Header.app-header {
    background: white;
    color: black;
}
</style>
