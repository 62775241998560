<template>
    <div class="Utils">
        <!-- 取消 -->
        <van-action-sheet v-model="sheetState.show" :actions="sheetState.actions" :cancel-text="$t('mx_mobile.Client.1585301326634')" @select="sheetSelect" @cancel="sheetCancel" />
        <van-dialog :value="promptState.show" :title="promptState.title" show-cancel-button :before-close="beforeClose">
            <!-- 请输入 -->
            <van-field v-model="text" :placeholder="$t('mx_mobile.common.1665816222322')" />
        </van-dialog>
        <van-popup v-model="chosenState.show" @click-overlay="onPickerCancel" :close-on-click-overlay="false" position="bottom">
            <van-picker show-toolbar title="" :columns="chosenState.columns" :value-key="chosenState.default" @cancel="onPickerCancel" @confirm="onPickerConfirm" />
        </van-popup>
        <select-country></select-country>
        <select-grassSilk></select-grassSilk>
        <select-backGlueMode></select-backGlueMode>
        <select-good></select-good>
        <!-- 翻译 -->
        <div ref="tlBtnShow" v-show="tlBtnShow" class="tsBtn">{{$t('mx_mobile.common.1665991726109')}}</div>
    </div>
</template>
<script>
import SelectCountry from '@/components/SelectCountry/component.vue'
import SelectGrassSilk from '@/components/SelectGrassSilk/component.vue'
import SelectBackGlueMode from '@/components/SelectBackGlueMode/component.vue'
import SelectGood from '@/components/SelectGood/component.vue'
export default {
    name: 'Utils',
    data() {
        return {
            text: '',
            sheetState: '',
            promptState: '',
            chosenState: '',
            tlBtnShow: false,
            showTimer: null
        }
    },
    created() {
        this.Global.utils.actionSheet.subscribe((state) => {
            this.sheetState = { ...state }
        })
        this.Global.utils.prompt.subscribe((state) => {
            this.promptState = { ...state }
        })
        this.Global.utils.chosen.subscribe((state) => {
            this.chosenState = { ...state }
        })
    },
    mounted() {
        //  this.addTextSelectEvent()
    },
    methods: {
        addTextSelectEvent() {
            let text
            document.ontouchstart = (e) => {
                if (e.target.contains(this.refs.tlBtnShow)) {
                }
                this.tlBtnShow = false
                this.showTimer && clearTimeout(this.showTimer)
            }
            document.ontouchend = () => {
                setTimeout(() => {
                    if (window.getSelection) {
                        text = window.getSelection()
                    } else if (document.getSelection) {
                        text = document.getSelection()
                    } else if (document.selection) {
                        text = document.selection.createRange().text
                    }
                    if (text.toString() != '') {
                        this._translate(text.toString())
                    }
                }, 100)
                //  document.execCommand('Copy') // 执行浏览器的复制命令
            }
        },

        async _translate() {
            this.showTimer && clearTimeout(this.showTimer)
            this.tlBtnShow = true
            this.showTimer = setTimeout(() => {
                this.tlBtnShow = false
            }, 5000)
            console.log('================')
        },

        sheetCancel() {
            this.Global.utils.actionSheet.reject({ msg: '取消了' })
        },
        sheetSelect(item) {
            this.Global.utils.actionSheet.resolve(item)
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                if (this.text === '') {
                    done(false)
                } else {
                    done()
                    this.Global.utils.prompt.resolve({ value: this.text })
                    this.text = ''
                }
            } else {
                this.text = ''
                this.Global.utils.prompt.reject({ msg: '取消了' })
                done()
            }
        },
        onPickerCancel() {
            this.Global.utils.chosen.reject({ msg: '取消了' })
        },
        onPickerConfirm(value, index) {
            this.Global.utils.chosen.resolve(value)
        }
    },
    components: {
        'select-country': SelectCountry,
        'select-grassSilk': SelectGrassSilk,
        'select-backGlueMode': SelectBackGlueMode,
        'select-good': SelectGood
    },
    watch: {
        'sheetState.show': function () {
            !this.sheetState.show && this.sheetCancel()
        }
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
.Utils {
    z-index: 9001;
    .tsBtn {
        @h: 54px;
        position: fixed;
        width: @h;
        height: @h;
        background-color: #fff;
        border-radius: @h / 2;
        right: -5px;
        top: @h;
        text-align: center;
        line-height: @h;
        user-select: none;
        box-shadow: 0px 0px 5px 5px rgba(208, 2, 27, 0.05);
        animation: move;
        @keyframes move {
            from {
                transform: translate3d(100%, 0, 0);
            }
            to {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}
</style>
