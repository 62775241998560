import * as types from './mutation-types'
import { apiBaseURL, RES_OK } from '@/libs/config'
import api from '@/libs/api'
import axios from 'axios'

// 设置个人配置信息
export function setIndividualConfigInfo({ commit, state }, option = {}) {
    let _this = this._vm
    _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.personalsetting_get, { params: {} })
        .then((res) => {
            if (res.data.code === _this.Global.config.RES_OK) {
                commit(types.SET_INDIVIDUALCONFIGINFO, res.data.data)
            } else {
                console.warn('setIndividualConfigInfo error!')
            }
        })
        .catch(err => {
            console.warn(err)
        })
}

// 获取系统动态组件下拉框值
export const setSysBoxValue = function ({ commit, state }, next = () => {}) {
    let _this = this._vm
    var p1 = new Promise((resolve, reject) => {
        _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.sysBoxValue_get, { params: {} }).then((res) => {
            if (res.data.code === _this.Global.config.RES_OK) {
                resolve(
                    res.data.data
                )
            } else {
                resolve(
                    []
                )
                _this.$toast.fail(res.data.msg)
                console.warn('setSysBoxValue error!')
            }
        }, (res) => {
            resolve(
                []
            )
            _this.$toast.fail(_this.Global.config.errorTitle)
        })
    })
    var p2 = new Promise((resolve, reject) => {
        _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.bizField_query, {
            params: {
                type: 'spinnerValues'
            }
        }).then((res) => {
            if (res.data.code === _this.Global.config.RES_OK) {
                resolve(
                    res.data.data
                )
            } else {
                resolve(
                    []
                )
                _this.$toast.fail(res.data.msg)
            }
        }, (res) => {
            resolve(
                []
            )
            _this.$toast.fail(_this.Global.config.errorTitle)
        })
    })
    var p3 = new Promise((resolve, reject) => {
        _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.dictionary + 'port').then((res) => {
            if (res.data.code === _this.Global.config.RES_OK) {
                resolve(
                    res.data.data
                )
            } else {
                resolve(
                    []
                )
            }
        })
    })
    var p4 = new Promise((resolve, reject) => {
        _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.AONIcustSort, { params: { type: 'list' } }).then((res) => {
            if (res.data.code === _this.Global.config.RES_OK) {
                resolve(
                    res.data.data
                )
            } else {
                resolve(
                    []
                )
            }
        })
    })
    Promise.all([p1, p2, p3, p4]).then((results) => {
        commit(types.SET_SYSBOXVALUE, results[0])
        if (next) {
            next()
        }
        commit(types.SET_CUSBOXVALUE, results[1])
        commit(types.SET_PORTLIST, results[2])
        commit(types.SET_AONICUSTSORT, results[3])
        let obj = {}
        results[2].forEach(item => {
            obj[item.portId] = {
                name: item.name
            }
        })
        commit(types.SET_PORTLISTSHOW, obj)
    })
}

// 获取公司全部人员下拉框值
export const setContactList = function ({ commit, state }) {
    axios.get(apiBaseURL + api.v2.accountDropList_get, {
        params: {
            dataType: 'contact',
            funType: 'all'
        }
    }).then(function (res) {
        if (res.data.code.toString() === RES_OK) {
            let list = res.data.data || []
            let obj = {}
            res.data.data.forEach(element => {
                obj[element.ctId] = element.realName
            })
            commit(types.SET_CONTACTVALUE, list)
            commit(types.SET_CONTACTLIST, obj)
        } else {
            console.warn('setContactList error!')
        }
    })
}
// 灰度控制信息
export const setGreyFunctions = function ({ commit, state }, options = {}) {
    const url = apiBaseURL + api.v2.greyFunctions
    const params = {
        corpId: options.cId
    }
    axios.get(url, { params })
        .then(res => {
            if (res.data.code.toString() === RES_OK) {
                commit(types.SET_GREYFUNCTIONS, res.data.data.functionCodes || [])
            } else {
                commit(types.SET_GREYFUNCTIONS, [])
                console.warn('setgreyfunctions error!')
            }
        })
        .catch(err => {
            commit(types.SET_GREYFUNCTIONS, [])
            console.log(err)
        })
}
export const setCurrency = function ({ commit, state }) {
    let _V = this._vm
    const url = _V.Global.config.apiBaseURL + _V.Global.api.v2.rateManage_curCode
    _V.axios.get(url).then((res) => {
        if (res.data.code.toString() === _V.Global.config.RES_OK) {
            let list = res.data.data.list || []
            commit(types.SET_CURRENCY, list)
            let obj = {}
            list.forEach(item => {
                obj[item.currencyCode] = JSON.parse(JSON.stringify(item))
            })
            commit(types.SET_CURRENCYSHOW, obj)
        }
    })
}
// 获取系统后端域名前缀
export const setDomain = function ({ commit, state }) {
    let _V = this._vm
    const url = _V.Global.config.apiBaseURL + _V.Global.api.UniversalInterface.domain
    _V.axios.get(url, { params: {} })
        .then((res) => {
            if (res.data.code.toString() === _V.Global.config.RES_OK) {
                commit(types.SET_DOMAIN, res.data.data)
            } else {
                console.warn('setDomain error!')
            }
        })
        .catch(err => {
            console.log('setDomain', err)
        })
}
// 获取系统后端域名前缀
export const setUnitList = function ({ commit, state }) {
    let _V = this._vm
    const url = _V.Global.config.apiBaseURL + _V.Global.api.v2.dictionary + 'unit'
    _V.axios.get(url, { params: {} })
        .then((res) => {
            if (res.data.code.toString() === _V.Global.config.RES_OK) {
                commit(types.SET_UNITLIST, res.data.data)
            } else {
                console.warn('setDomain error!')
            }
        })
        .catch(err => {
            console.log('setDomain', err)
        })
}
