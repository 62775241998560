import Vue from 'vue'
Vue.directive('imgsrc', {
  bind: function (el, binding, vnode) { // 被绑定
    let initImg = el.getAttribute('data-initsrc') || vnode.context.Global.errorImg
    let retryTimes = 0
    el.src = initImg// 初始化图片
    let Img = new Image()
    Img.src = binding.value
    Img.onload = function () {
      // 加载成功后的图片
      el.src = Img.src
      retryTimes = 0
    }
    Img.onerror = function () {
      // 加载失败后的图片
      el.src = initImg
      if (retryTimes < 5) {
          const reload = setTimeout(() => {
            Img.src = binding.value
            clearTimeout(reload)
            retryTimes++
          }, 5000)
      }
    }
  },
  inserted: function () { // 绑定到节点

  },
  update: function (el, binding, vnode) { // 组件更新
    let initImg = el.getAttribute('data-initsrc') || vnode.context.Global.errorImg
    // 指令数据更新后图片的处理
    let Img = new Image()
    Img.src = binding.value
    Img.onload = function () {
      el.src = Img.src
    }
    Img.onerror = function () {
      el.src = initImg
    }
  },
  componentUpdated: function () { // 组件更新完成

  },
  unbind: function () { // 解绑

  }
})
