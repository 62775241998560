import Vue from 'vue'
import * as types from './mutation-types'
export default {
  [types.SET_CONST]: (state, {
    id,
    item
  }) => {
    Vue.set(state.const, id, item)
  },
  [types.SET_BOXINDEX]: (state, data) => {
    state.boxIndex = data
  },
  [types.SET_BOXAARRY]: (state, data) => {
    state.boxAarry = data
  },
  [types.SET_BOXMID]: (state, data) => {
    state.boxMid = data
  },
  [types.SET_BOXNAME]: (state, data) => {
    state.boxName = data
  },
  [types.SET_ACTIONTARGET]: (state, data) => {
    state.actionTarget = data
  },
  [types.SET_CUURENTADDRESS]: (state, data) => {
    state.currentAddress = data
  },
  [types.SET_CURRENTCTID]: (state, data) => {
    state.currentCtid = data
  }
}
