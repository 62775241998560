import * as config from './config'
import cryptoAES from 'crypto-js/aes'
import cryptoEncUtf8 from 'crypto-js/enc-utf8'
import { ImagePreview } from 'vant'
/**
 * 获取系统Token
 */
export const getToken = () => ({
    [config.accessToken]: getItem(config.accessToken),
    [config.individualAccessToken]: getItem(config.individualAccessToken)
})
/**
 * 设置系统Token
 */
export const setToken = ({ accessToken, individualAccessToken }) => {
    if (accessToken) { setItem(config.accessToken, accessToken) }
    if (individualAccessToken) { setItem(config.individualAccessToken, individualAccessToken) }
}

// setItem
export const setItem = (key, value) => {
    window.localStorage.setItem(key, value)
}

// getItem
export const getItem = (key) => {
    return window && window.localStorage.getItem(key)
}

// removeItem
export const removeItem = (key) => {
    window.localStorage.removeItem(key)
}

// 获取isApp
export const getIsApp = () => {
    return JSON.parse(getItem('isApp') || null)
}

// clear
export const clearItem = () => {
    window.localStorage.clear()
}

/**
 * 判断是否是共创企业
 * @param {Number} cId 企业ID
 */
export const isGongChuang = (cId) => {
    return [1973081, '1973081', 320416, '320416'].includes(cId)
}

/**
 * 检查文件后缀，是否有这个图标，没有的话，给unknow
 * console.log(isHasSuffix('doc'))
 */
export const isHasSuffix = suffix => {
    let suffixArray = ['xls', 'doc', 'docx', 'ppt', 'pptx', 'avi', 'rar', 'mp3', 'mp4', 'zip', 'html', 'pdf', 'eps', 'xlsx', 'jpg']
    suffix = suffix.toLowerCase()
    return suffixArray.indexOf(suffix) === -1 ? 'unknow' : suffix
}

/* 文件大小计算 */
export const byteCalc = size => {
    if (size > 1048576) {
        return (size / 1048576).toFixed(2) + ' M'
    } else {
        return (size / 1024).toFixed(2) + ' KB'
    }
}

/**
 * 判断是否是字符串类型
 */
export const isString = (str, isEffective = false) => {
    if (Object.prototype.toString.call(str) === '[object String]') {
        if (isEffective) {
            return !!str
        } else {
            return true
        }
    } else {
        return false
    }
}

/**
 * 判断是否是对象
 */
export const isObject = (obj, isEffective = false) => {
    if (Object.prototype.toString.call(obj) === '[object Object]') {
        if (isEffective) {
            return !!Object.keys(obj).length
        } else {
            return true
        }
    } else {
        return false
    }
}

/**
 * 判断是否是数组类型，
 * 并且是否是有效数组
 */
export const isArray = (array, isEffective = false) => {
    if (Object.prototype.toString.call(array) === '[object Array]') {
        if (isEffective) {
            return array.length > 0
        } else {
            return true
        }
    } else {
        return false
    }
}

/**
 * 批注圆点色彩
 */
export const commentsColor = flag => {
    let colors = [
        '#ff2b19',
        '#3375ff',
        '#ffa300',
        '#2ecce6',
        '#34cc14',
        '#9e80ff',
        '#99371f',
        '#ff66cc',
        '#e6dd00',
        '#b4b7bf'
    ]
    return 'color:' + colors[flag - 1]
}
/**
 * 标签背景色
 */
export const tagsBgColor = colorId => {
    let str = 'color:#fff;background-color:'
    let colors = [
        '#FF7165',
        '#5EA3F6',
        '#FFB735',
        '#37CBE3',
        '#8BD867',
        '#9B80F2',
        '#763626',
        '#FD8EC4',
        '#BFBF17',
        '#909399',
        '#D0021B'
    ]
    return str + colors[colorId - 1]
}

/**
 * 上传文件到文件文件服务器
 */
export const fileUpload = option => {
    var uploadUrl
    if (option && option.url && option.url !== '') {
        uploadUrl = option.url
    } else {
        console.error('upload url undefined')
        return
    }

    var FileList = []
    if (option.FileList && option.FileList.length > 0) {
        FileList = option.FileList
    } else {
        console.log('no FileList')
        return
    }
    // 上传方式
    var method
    if (option.method && option.method !== '') {
        method = option.method
    } else {
        method = 'PUT'
    }

    // FormData 对象 key
    var formName = option.formName || 'fileToUpload'
    var i = 0
    var xhr

    async function Upload(file) {
        // console.log(file);
        // 1.准备FormData
        var fd = new FormData()
        var fileName = file.name === undefined ? new Date() * 1 + '.' + file.type.split('/')[1].toLowerCase() : file.name
        if (option && option.compress && file.type.indexOf('image') != -1) {
            option.compressStart && option.compressStart()
            file = await imageUtils.compress(file)
            option.compressEnd && option.compressEnd()
        }

        var fileSize = file.size
        fd.append(formName, file, fileName)
        if (option.accessToken) fd.append('accessToken', option.accessToken)

        // 2.创建xhr对象
        if (!xhr) {
            xhr = new XMLHttpRequest()
        }

        // 监听状态，实时响应
        // xhr 和 xhr.upload 都有progress事件，xhr.progress是下载进度，xhr.upload.progress是上传进度
        // 这里监听上传进度事件，文件在上次的过程中，会多次触发该事件，返回一个event事件对象
        xhr.upload.onprogress = function (event) {
            if (event.lengthComputable) { // 返回一个  长度可计算的属性，文件特别小时，监听不到，返回false
                // 四舍五入
                var percent = Math.round(event.loaded * 100 / event.total) // event.loaded:表示当前已经传输完成的字节数。
                // event.total:当前要传输的一个总的大小.通过传输完成的除以总的，就得到一个传输比率
                event['percent'] = percent
                option && option.onprogress && option.onprogress(event)
            }
        }

        // 传输开始事件
        xhr.onloadstart = function (event) {
            option && option.onloadstart && option.onloadstart(event)
        }
        // xhr.abort();//调用该方法停止ajax上传，停止当前的网络请求

        // 每个文件上传成功
        xhr.onload = function (event) {
            option && option.onload && option.onload(Object.assign(JSON.parse(event.target.response), {
                name: fileName,
                size: fileSize
            }))
            i++
            if (i < FileList.length) {
                setTimeout(function () {
                    Upload(FileList[i])
                }, 200)
            }
        }

        // ajax过程发生错误事件
        xhr.onerror = function (event) {
            option && option.onerror && option.onerror(event)
        }

        // ajax被取消，文件上传被取消，说明调用了 xhr.abort();  方法，所触发的事件
        xhr.onabort = function (event) {
            option && option.onabort && option.onabort(event)
        }

        // loadend传输结束，不管成功失败都会被触发
        xhr.onloadend = function (event) {
            option && option.onloadend && option.onloadend(event)
        }

        // 发起ajax请求传送数据
        xhr.open(method, uploadUrl, true)
        xhr.send(fd) // 发送文件
    }
    Upload(FileList[i])
    return {
        abort: function () {
            xhr.abort()
        }
    }
}

/*
 * 上传到api接口，支持接口数据和文件上传
 */
export const sendDataFile = async _option => {
    let {
        data,
        files,
        fileKey,
        method,
        compress,
        url,
        compressStart,
        compressEnd,
        onprogress,
        onloadstart,
        onload,
        onerror,
        onabort,
        onloadend
    } = Object.assign({
        data: {},
        files: [],
        fileKey: '',
        method: 'post',
        compress: 'true',
        url: '',
        compressStart() { },
        compressEnd() { },
        onprogress(event) { },
        onloadstart(event) { },
        onload(res) { },
        onerror(event) { },
        onabort(event) { },
        onloadend(event) { }
    }, _option)
    let fd = new FormData()
    Object.keys(data)
        .forEach(key => {
            fd.append(key, data[key])
        })

    /* 压缩 */
    let taskStack = []
    let nameStack = []
    for (let i = 0; i < files.length; i++) {
        if (compress && files[i].type.indexOf('image') != -1) {
            taskStack.push(imageUtils.compress(files[i]))
            nameStack.push({ name: files[i].name, index: i })
        } else {
            fd.append(`${fileKey}${i == 0 ? '' : i}`, files[i], files[i].name)
        }
    }

    if (taskStack.length > 0) {
        compressStart()
        let resArr = await Promise.all(taskStack)
        resArr.forEach((item, index) => {
            let nameItem = nameStack[index]
            fd.append(`${fileKey}${nameItem.index == 0 ? '' : nameItem.index}`, item, nameItem.name)
        })
        compressEnd()
    }
    /* 压缩结束 */

    let xhr = new XMLHttpRequest()
    // 监听状态，实时响应
    // xhr 和 xhr.upload 都有progress事件，xhr.progress是下载进度，xhr.upload.progress是上传进度
    // 这里监听上传进度事件，文件在上次的过程中，会多次触发该事件，返回一个event事件对象
    xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
            // 返回一个  长度可计算的属性，文件特别小时，监听不到，返回false
            // 四舍五入
            var percent = Math.round(event.loaded * 100 / event.total) // event.loaded:表示当前已经传输完成的字节数。
            // event.total:当前要传输的一个总的大小.通过传输完成的除以总的，就得到一个传输比率
            event['percent'] = percent
            onprogress(event)
        }
    }

    // 传输开始事件
    xhr.onloadstart = function (event) {
        onloadstart(event)
    }
    // xhr.abort();//调用该方法停止ajax上传，停止当前的网络请求

    // 文件上传成功
    xhr.onload = function (event) {
        try {
            if (typeof event.target.response === 'string') {
                onload(JSON.parse(event.target.response))
            } else {
                onload(event.target.response)
            }
        } catch (e) {
            onload(event.target.response)
        }
    }

    // ajax过程发生错误事件
    xhr.onerror = function (event) {
        onerror(event)
    }

    // ajax被取消，文件上传被取消，说明调用了 xhr.abort();  方法，所触发的事件
    xhr.onabort = function (event) {
        onabort(event)
    }

    // loadend传输结束，不管成功失败都会被触发
    xhr.onloadend = function (event) {
        onloadend(event)
    }

    // 发起ajax请求传送数据
    xhr.open(method, url, true)
    xhr.send(fd) // 发送文件
}
/**
 * js获取文件后缀
 */
export const getSuffix = function (fileName) {
    var fileNameArray = fileName.split('.')
    return fileNameArray[fileNameArray.length - 1]
}
/**
 * 加密
 */
export const encrypt = data => {
    var ciphertext = cryptoAES.encrypt(data, 'laifuyun.com')
    return ciphertext.toString()
}

/**
 * 解密
 */
export const decrypt = data => {
    var bytes = cryptoAES.decrypt(data, 'laifuyun.com')
    var plaintext = bytes.toString(cryptoEncUtf8)
    return plaintext
}

/* 头部右侧按钮 */
export const rightMenu = (function () {
    const state = {
        title: '更多',
        icon: '',
        show: false,
        actions: []
    }
    const _state = {
        listeners: []
    }
    const _dispatch = () => {
        _state.listeners.forEach((listener) => listener(state))
    }

    const subscribe = listener => { _state.listeners.push(listener) }

    function _setMenu(text = '更多', icon = '', display) {
        Object.assign(state, { actions: [], title: text, icon, show: display === 'show' })
        const subscribe = listener => {
            state.actions.push(listener)
            _dispatch()
        }
        _dispatch()
        return { onClick: subscribe }
    }

    function setMenu(text = '更多', display = 'show') {
        return _setMenu(text, '', display)
    }

    function setIconMenu(icon = '', display = 'show') {
        return _setMenu('', icon, display)
    }

    function clearMenu() {
        Object.assign(state, { show: false, icon: '', actions: [] })
        _dispatch()
    }

    return {
        setMenu,
        setIconMenu,
        clearMenu,
        subscribe
    }
})()

/* actionSheet */
export const actionSheet = (function () {
    const state = {
        actions: [],
        show: false
    }

    const _state = {
        resolve: '',
        reject: '',
        listeners: []
    }

    const _dispatch = () => {
        _state.listeners.forEach((listener) => listener(state))
    }
    const subscribe = listener => { _state.listeners.push(listener) }

    function open(actions = []) {
        return new Promise((resolve, reject) => {
            Object.assign(_state, { resolve, reject })
            Object.assign(state, { actions, show: true })
            _dispatch()
        })
    }

    function close() {
        if (state.show) {
            reject({ msg: '关闭了' })
            return true
        }
        return false
    }

    function resolve(data) {
        Object.assign(state, { actions: [], show: false })
        _dispatch()
        _state.resolve && _state.resolve(data)
    }

    function reject(data) {
        if (state.show) {
            Object.assign(state, { actions: [], show: false })
            _dispatch()
            _state.reject && _state.reject(data)
        }
    }

    return {
        open,
        close,
        resolve,
        reject,
        subscribe
    }
})()

/* Prompt */
export const prompt = (function () {
    const state = {
        show: false,
        title: ''
    }
    const _state = {
        listeners: [],
        resolve: '',
        reject: ''
    }

    const subscribe = listener => { _state.listeners.push(listener) }

    const _dispatch = () => {
        _state.listeners.forEach(listener => { listener(state) })
    }
    // '请输入'
    function open({ title = this.$t('mx_mobile.common.1665816222322') }) {
        return new Promise((resolve, reject) => {
            Object.assign(state, { title, show: true })
            Object.assign(_state, { resolve, reject })
            _dispatch()
        })
    }

    function close() {
        if (state.show) {
            reject({ msg: this.$t('mx_mobile.common.1665991533430') }) // '关闭了'
            return true
        }
        return false
    }

    function resolve(data) {
        Object.assign(state, { title: '', show: false })
        _dispatch()
        _state.resolve && _state.resolve(data)
    }

    function reject(data) {
        if (state.show) {
            Object.assign(state, { title: '', show: false })
            _dispatch()
            _state.reject && _state.reject(data)
        }
    }
    return {
        open,
        close,
        resolve,
        reject,
        subscribe
    }
})()

/* chosen */
export const chosen = (function () {
    const state = {
        show: false,
        defalut: '',
        columns: []
    }
    const _state = {
        resolve: '',
        reject: '',
        listeners: []
    }
    const subscribe = listener => { _state.listeners.push(listener) }
    const _dispatch = () => {
        _state.listeners.forEach(listener => {
            listener(state)
        })
    }

    function open(columns = [], defalut = '') {
        return new Promise((resolve, reject) => {
            Object.assign(state, {
                show: true,
                defalut,
                columns
            })
            Object.assign(_state, {
                resolve,
                reject
            })
            _dispatch()
        })
    }

    function close() {
        if (state.show) {
            reject({ msg: '关闭了' })
            return true
        }
        return false
    }

    function resolve(data) {
        Object.assign(state, {
            columns: [],
            defalut: '',
            show: false
        })
        _dispatch()
        _state.resolve && _state.resolve(data)
    }

    function reject(data) {
        if (state.show) {
            Object.assign(state, { columns: [], defalut: '', show: false })
            _dispatch()
            _state.reject && _state.reject(data)
        }
    }
    return {
        open,
        close,
        subscribe,
        resolve,
        reject
    }
})()

/* test: 组件的状态管理 */
const createStore = function (reducer) {
    let state = null
    let listeners = []
    let getState = () => state
    let subscribe = listener => listeners.push(listener)
    let dispatch = action => {
        state = reducer && reducer(state, action)
        listeners.forEach(listener => listener())
    }
    dispatch({})
    return { getState, subscribe, dispatch }
}

export const chosenTest = (function () {
    const store = createStore((state, acton) => {
        if (!state) {
            return {
                show: false,
                defalut: '',
                columns: []
            }
        }
        switch (acton.type) {
            case 'updata':
                return { ...state, ...action.data }
            default:
                return state
        }
    })

    const onStateChange = fn => {
        store.subscribe(() => {
            fn && fn(store.getState())
        })
    }
    const open = (columns = [], defalut = '') => {
        store.dispatch({
            type: 'update',
            data: {
                show: true,
                defalut,
                columns
            }
        })
    }
    const close = () => { }

    return {
        onStateChange,
        open,
        close
    }
})()
/* test 结束 */

export const filePreview = (function () {
    const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
    const officeArr = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
    const pdfArr = ['pdf']
    const videoArr = ['mp4', 'mov', 'wmv', 'flv', 'avi', 'avchd', 'webm', 'mkv']

    function _fileType(types, fileExtName) {
        return types.includes(fileExtName.toLowerCase())
    }

    function isSupport(fileExtName = '') {
        let extName = fileExtName.toLowerCase()
        return imgArr.includes(extName) ||
            officeArr.includes(extName) ||
            pdfArr.includes(extName) ||
            videoArr.includes(extName)
    }

    function open(url, fileExtName, fileName, needEncode = true) {
        if (_fileType(videoArr, fileExtName)) {
            if (getIsApp()) {
                window.open({ url, downloadUrl: url, fileName, extName: fileExtName, isShare: true })
            } else {
                window.open(url)
            }
        }
        if (_fileType(imgArr, fileExtName)) {
            if (getIsApp()) {
                window.open({ url, downloadUrl: url, fileName, extName: fileExtName, isShare: true })
            } else {
                ImagePreview({
                    images: [url],
                    showIndex: false
                })
            }
        }
        if (_fileType(officeArr, fileExtName)) {
            if (new RegExp(config.downloadBaseUrl).test(url)) {
                url = url.replace(config.downloadBaseUrl, config.previewUrl)
            }
            let _url = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}&wdStartOn=1&wdPrint=1&wdEmbedCode=1`
            if (getIsApp()) {
                window.open({ url: _url, downloadUrl: url, fileName: fileName, extName: fileExtName, isShare: true })
            } else {
                window.open(_url)
            }
        }
        if (_fileType(pdfArr, fileExtName)) {
            let _url = `${config.apiBaseURL}files/pdf?src=${needEncode ? encodeURIComponent(url) : url}`
            if (getIsApp()) {
                window.open({ url: `${config.serverHost}/pdf/web/viewer.html?file=${encodeURIComponent(_url)}`, downloadUrl: url, fileName: fileName, extName: fileExtName, isShare: true })
            } else {
                window.open(`${config.serverHost}/pdf/web/viewer.html?file=${encodeURIComponent(_url)}`)
            }
        }
    }
    return {
        isSupport,
        open
    }
}())

/* 💕 图片压缩 */
export const imageUtils = (function () {
    if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function (callback, type, quality) {
                let binStr = atob(this.toDataURL(type, quality).split(',')[1])
                let len = binStr.length
                let arr = new Uint8Array(len)
                for (var i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                callback(new Blob([arr], { type: type || 'image/png' }))
            }
        })
    }

    const _state = {
        quality: 0.7
    }

    function _canvasDataURL(urlData, fileType, quality, resolve) {
        const img = new Image()
        img.src = urlData
        img.onload = function () {
            let w = this.width
            let h = this.height
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            let anw = document.createAttribute('width')
            let anh = document.createAttribute('height')

            anw.nodeValue = w
            anh.nodeValue = h
            canvas.setAttributeNode(anw)
            canvas.setAttributeNode(anh)

            ctx.drawImage(this, 0, 0, w, h)
            let _quality = _state.quality
            if (quality && quality <= 1 && quality >= 0) {
                _quality = quality
            }

            /*  const base64 = canvas.toDataURL(fileType || 'image/png', _quality)
             _state.resolve && _state.resolve(base64) */
            canvas.toBlob(blob => {
                resolve(blob)
            }, fileType, _quality)
        }
    }

    function compress(file, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function (e) {
                const re = e.target.result
                _canvasDataURL(re, file.type, quality, resolve)
            }
        })
    }

    function getRoundedCanvas(sourceCanvas, type) {
        var canvas = document.createElement('canvas')
        var context = canvas.getContext('2d')
        var width = sourceCanvas.width
        var height = sourceCanvas.height
        canvas.width = width
        canvas.height = height
        context.imageSmoothingEnabled = true
        context.drawImage(sourceCanvas, 0, 0, width, height)
        context.globalCompositeOperation = 'destination-in'
        context.beginPath()
        if (type == 'circle') {
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
        }
        context.fill()
        return canvas
    }

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onload = (e) => {
                resolve(e.target.result)
            }
            // readAsDataURL
            fileReader.readAsDataURL(blob)
            fileReader.onerror = () => {
                reject(new Error('blobToBase64 error'))
            }
        })
    }
    return {
        compress,
        getRoundedCanvas,
        blobToBase64
    }
}())
/**
 * 判断对象是否有属性
 */
// export const isEmptyObject = function(e) {
//     var t
//     for (t in e) return !1
//     return !0
// }
export const isEmptyObject = function (obj) {
    return typeof obj === 'object' && Object.keys(obj).length === 0
}
export const delEmptyStr = function (content, recurse) {
    for (var i in content) {
        if (content[i] === '') {
            delete content[i]
        } else if (recurse && typeof content[i] === 'object') {
            delEmptyStr(content[i], recurse)
        }
    }
}
export const delEmptyObj = function (content, recurse) {
    for (var i in content) {
        if (isEmptyObject(content[i])) {
            delete content[i]
        } else if (recurse && typeof content[i] === 'object') {
            delEmptyObj(content[i], recurse)
        }
    }
}
export const delEmptyStrObj = function (content, recurse) {
    delEmptyStr(content, recurse)
    delEmptyObj(content, recurse)
}
/**
 * 判断字符串是否被编码
 */
export const AddressCharacterJudgment = function (url) {
    let isEncode = true
    if (url.match('.*[\\ \"\\<\\>\\{\\}|\\\\^~\\[\\]].*')) {
        isEncode = false
    }
    if (url.match('.*[^\\x00-\\xff].*')) {
        isEncode = false
    }
    try {
        let decodeUrl = URLDecoder.decode(url, 'UTF-8')
        if (decodeUrl == url) {
            isEncode = false
        }
    } catch (error) {
        isEncode = false
    }
    if (isEncode) {
        return url
    }
    return encodeURI(url).replace(/\#/ig, '%23').replace(/\+/ig, '%2B')
}

/**
 * 文件下载
 * @param {*} sUrl
 */
export const downloadFile = function (sUrl, data = {}, fileName) {
    // iOS devices do not support downloading. We have to inform user about this.
    // if (/(iP)/g.test(navigator.userAgent)) {
    //     alert('Your device does not support files downloading. Please try again in desktop browser.')
    //     return false
    // }
    let str = ''
    if (sUrl.indexOf('?') === -1) {
        str = '?'
    } else {
        str = '&'
    }
    Object.assign(data, getToken())
    Object.keys(data).forEach(key => {
        str += key + '=' + data[key] + '&'
    })
    sUrl = sUrl + str
    sUrl = sUrl.substring(0, sUrl.length - 1)

    // If in Chrome or Safari - download via virtual link click
    var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1
    var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1
    if (isChrome || isSafari || 1 == '1') {
        // Creating new link node.
        var link = document.createElement('a')
        link.href = sUrl
        link.download = ''
        if (fileName !== undefined) {
            // Set HTML5 download attribute. This will prevent file from opening if supported.
            link.download = fileName
        }

        // Dispatching click event.
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents')
            e.initEvent('click', true, true)
            link.dispatchEvent(e)
            URL.revokeObjectURL(sUrl)
            return true
        }
    }
    // Force file download (whether supported by server).
    if (sUrl.indexOf('?') === -1) {
        sUrl += '?download'
    }
    window.open(sUrl, '_self')
    return true
}
// 小于10,在前面补0
export const addZero = function (i) {
    return (i < 10 ? '0' : '') + i
}
// 日期格式转换 参数: 毫秒数, 格式 formatValidTime(validTime, 'yyyy-MM-dd')
export const formatValidTime = function (time, format = 'yyyy-MM-dd') {
    try {
        if (time == null) return null
        let t = new Date(time)
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
            if (a === 'yyyy') {
                return addZero(t.getFullYear())
            } else if (a === 'MM') {
                return addZero(t.getMonth() + 1)
            } else if (a === 'dd') {
                return addZero(t.getDate())
            } else if (a === 'HH') {
                return addZero(t.getHours())
            } else if (a === 'mm') {
                return addZero(t.getMinutes())
            } else if (a === 'ss') {
                return addZero(t.getSeconds())
            }
        })
    } catch (err) {
        console.log(err)
        return ''
    }
}
// 根据某日获取该日的周一和周日日期 param: yyyy-MM-dd, return: array
export const getMonDayAndSunDay = function (dateValue) {
    try {
        let arr = dateValue.split('-')
        // 月份-1 因为月份从0开始 构造一个Date对象
        let date = new Date(arr[0], arr[1] - 1, arr[2])
        let dateOfWeek = date.getDay() // 返回当前日期的在当前周的某一天（0～6--周日到周一）
        let dateOfWeekInt = parseInt(dateOfWeek, 10) // 转换为整型
        if (dateOfWeekInt == 0) dateOfWeekInt = 7 // 如果是周日
        let aa = 7 - dateOfWeekInt // 当前于周末相差的天数
        let temp2 = parseInt(arr[2], 10) // 按10进制转换，以免遇到08和09的时候转换成0
        let sunDay = temp2 + aa // 当前日期的周日的日期
        let monDay = sunDay - 6 // 当前日期的周一的日期
        let startDate = new Date(arr[0], arr[1] - 1, monDay)
        let endDate = new Date(arr[0], arr[1] - 1, sunDay)
        let sm = addZero(parseInt(startDate.getMonth()) + 1) // 月份+1 因为月份从0开始
        let em = addZero(parseInt(endDate.getMonth()) + 1)
        let start = startDate.getFullYear() + '-' + sm + '-' + addZero(startDate.getDate())
        let end = endDate.getFullYear() + '-' + em + '-' + addZero(endDate.getDate())
        let result = []
        result.push(start)
        result.push(end)
        return result
    } catch (err) {
        console.log('getMonDayAndSunDay-err')
        return ['', '']
    }
}

// 获取当前日期的周一
export const getMonday = function (dd) {
    let week = dd.getDay() // 获取时间的星期数，周日是0
    let minus = week ? week - 1 : 6
    dd.setDate(dd.getDate() - minus) // 获取minus天前的日期
    let y = dd.getFullYear()
    let m = dd.getMonth() + 1 // 获取月份
    m = addZero(m)
    let d = dd.getDate()
    d = addZero(d)
    return y + '-' + m + '-' + d
}
// 根据周获取当前周最后一天,再获取那天的所在周的周一
// 'week：2018-W35  return：2018-8-27
export const getDayForWeek = function (week) {
    if (week.length < 0) {
        return false
    }
    let myDate = new Date(week.split('-')[0], 0, 1) // 设置当前年的1月1日
    let myweek = myDate.getDay() // 获取时间的星期数，周日是0
    myweek = myweek ? myweek - 1 : 6
    // 如果1号<周五，则为第一周，>周五则为去年最后一周
    if (myweek < 4) {
        // 前移日期
        myDate.setDate(myDate.getDate() - myweek)
    } else {
        // 后移日期
        myDate.setDate(myDate.getDate() + 7 - myweek)
    }
    myDate.setDate(myDate.getDate() + parseInt(week.split('W')[1] - 1) * 7) // 获取周的加成
    return getMonday(myDate)
}
// 获取日期在当前年的周数
// day: 2018-8-27 week:2018-W05
export const getWeekForDay = function (day) {
    let da = ''
    if (day == undefined) {
        let now = new Date()
        da = getMonday(now)
    } else {
        da = day
    }
    // （当前日期-当年第一周第一天）/7 = 周次
    let date1 = new Date(Date.parse(da)) // 当前日期
    date1 = getMonday(date1)
    date1 = new Date(Date.parse(date1))
    date1.setDate(date1.getDate() + 3) // 获取当前日期的周四(针对每年第一个月不同周次的计算)
    let date2 = new Date(date1.getFullYear(), 0, 1) // 当年的1月1号
    let dateWeekNum = date2.getDay() // 获取时间的星期数，周日是0
    dateWeekNum = dateWeekNum ? dateWeekNum - 1 : 6
    // 如果1号<周五，则为第一周，>周五则为去年最后一周
    if (dateWeekNum < 4) {
        // 前移日期（本周一）
        date2.setDate(date2.getDate() - dateWeekNum)
    } else {
        // 后移日期（下周一）
        date2.setDate(date2.getDate() + 7 - dateWeekNum)
    }
    // Math.round 四舍五入到整数
    let d = Math.round((date1.valueOf() - date2.valueOf()) / 86400000) // 86400000==1000毫秒 * 60秒 * 60分钟* 24小时==天数
    // 得到年数周数
    let year = date1.getFullYear()
    // 向上取整
    let week = Math.ceil((d + 1) / 7)
    week = week < 10 ? ('0' + week) : week
    return year + '-W' + week
}

export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16 | 0
        let v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

const charSet = (function () {
    let set = new Set(['-', '_', '.', '!', '~', '*', "'", '(', ')', ';', '/', '?', ':', '@', '&', '=', '+', '$', ',', '#'])
    let start = 'a'.charCodeAt()
    let end = 'z'.charCodeAt()
    for (let i = start; i <= end; i++) {
        set.add(String.fromCharCode(i))
    }

    start = 'A'.charCodeAt()
    end = 'Z'.charCodeAt()
    for (let i = start; i <= end; i++) {
        set.add(String.fromCharCode(i))
    }

    for (let i = '0'; i <= '9'; i++) {
        set.add(i.toString())
    }
    return set
}())

export const urlIsEncode = function (url, returnUrl = true) {
    let isEncode = true

    for (let i = 0; i < url.length; i++) {
        let c = url.charAt(i)
        if (charSet.has(c)) {
            continue
        }

        if (c == '%' && (i + 2) < url.length) { // 判断是否符合urlEncode规范
            let c1 = url.charAt(++i)
            let c2 = url.charAt(++i)
            if (isDigit16Char(c1) && isDigit16Char(c2)) {
                continue
            }
        }
        isEncode = false // 其他字符，肯定需要urlEncode
        break
    }
    if (!returnUrl) {
        return isEncode
    }
    if (isEncode) {
        return url.replace(/\#/ig, '%23').replace(/\+/ig, '%2B')
    }
    return encodeURI(url).replace(/\#/ig, '%23').replace(/\+/ig, '%2B')

    function isDigit16Char(c) {
        return (c >= '0' && c <= '9') || (c >= 'A' && c <= 'F')
    }
}

/**
 * 延时
 * @param {number} duration
 * @returns {Promise}
 */
export const sleep = function (duration = 0) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, duration)
    })
}

export const getQueryString = (name) => {
    const { href } = window.location
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const result = href.substr(href.indexOf('?') + 1).match(reg)
    console.log('中英文name', name, result)
    if (result != null) {
        return unescape(result[2])
    } else {
        return null
    }
}
