<template>
    <!-- 重查搜索框 -->
    <div class="SearchBar" :class="{'hasCancelBtn':showBtn}">
        <div class="inputBox">
            <i class="iconfont icon-search"></i>
            <form v-on:submit.prevent action="" class="formBox">
                <input :value="keywords" :placeholder="placeholder" type="search" @input="handleInput" />
            </form>
        </div>
        <div class="button" @click="onClick" v-if="showBtn">
            <!-- 取消 -->
            {{$t('mx_mobile.Client.1585301326634')}}
        </div>
    </div>
</template>
<script>
export default {
    name: 'SearchBar',
    props: {
        showBtnNow: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: function fc() { return this.$t('mx_mobile.common.1665816222322') } // 请输入
        }
    },
    data() {
        return {
            keywords: this.value
        }
    },
    computed: {
        showBtn() {
            if (this.showBtnNow) {
                return true
            } else {
                return !!this.keywords
            }
        }

    },
    methods: {
        handleInput(event) {
            let value = event.target.value
            this.keywords = value
            this.$emit('input', value)
        },
        onClick() {
            this.keywords = ''
            this.$emit('input', '')
            this.$emit('btnClick')
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
