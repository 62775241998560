export default {
  const: {},
  boxIndex: 0, // 详情id
  boxAarry: [], // 当前文件夹所有数组ID
  boxMid: 0, // 文件夹id
  boxName: '', // 文件夹名称
  actionTarget: '', // 操作类型
  currentAddress: '', // 当前账号
  currentCtid: '' // 当前ctid
}
