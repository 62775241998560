class SelectCountry {
    constructor() {
        this._state = {
            reject: null,
            resolve: null,
            listeners: [],
            radio: '',
            value: ''
        }
        this.state = {
            show: false,
            defaultValue: '',
            options: [],
            radio: '',
            value: ''
        }
    }

    onChange(listener) { this._state.listeners.push(listener) }

    _dispatch() {
        this._state.listeners.forEach(listener => {
            listener(this.state)
        })
    }

    show(selected, options = []) {
        return new Promise((resolve, reject) => {
            Object.assign(this.state, { show: true, defaultValue: selected, options })
            Object.assign(this._state, { resolve, reject })
            this._dispatch()
        })
    }

    _resetState() {
        Object.assign(this.state, { show: false, defaultValue: '', options: [], radio: '', value: '' })
        this._dispatch()
    }

    select(data) {
        this._resetState()
        this._state.resolve && this._state.resolve(data)
    }

    close() {
        if (this.state.show) {
            this._resetState()
            this._state.reject && this._state.reject({ message: this.$t('mx_mobile.common.1665975885751') }) // '取消选择'
        }
    }

    seatchList(data) {
        this._state.resolve && this._state.resolve(data)
    }
}

export default new SelectCountry()
