import * as types from './mutation-types'

export function fetchConst({
  commit
}, id) {
  let _this = this._vm
  return _this.axios.get('https://alph.laifuyun.com' + _this.Global.api.timezone, {}).then((response) => {
    let item = {
      title: '我是标题'
    }
    commit(types.SET_CONST, {
      id,
      item
    })
  }).catch((error) => {
    console.error(error)
  })
}
