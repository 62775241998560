import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
    Locale
} from 'vant'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import enUS from 'vant/lib/locale/lang/en-US'
import mxzhcn from '../../../i18n/json/zh-cn.json'
import mxzhtw from '../../../i18n/json/zh-tw.json'
import mxen from '../../../i18n/json/en.json'
import axios from 'axios'

let vantLocale = {
    'zh-cn': zhCN,
    'zh-tw': zhHK,
    'en': enUS
}

Vue.use(VueI18n)

let messages = {
    'zh-cn': mxzhcn,
    'zh-tw': mxzhtw,
    'en': mxen
}

let locale = 'zh-cn'

const i18n = new VueI18n({
    locale,
    messages
})

let setI18nLanguage = lang => {
    i18n.locale = lang
    Locale.use(lang, vantLocale[lang])
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    document.querySelector('body').setAttribute('class', lang)
}

Vue.prototype.$setI18nLanguage = setI18nLanguage

export default i18n
