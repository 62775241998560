export default {
    language: 'zh-cn',
    // 个人配置信息
    individualConfigInfo: {},
    /* 公司信息 */
    companie: {},
    // 系统动态组件下拉框值
    sysBoxValue: [],
    // 系统动态组件自定义下拉框值
    cusBoxValue: [],
    //  钉钉userInfo
    userInfo: [],
    // 标题
    headerTitle: '',
    formKey: '', // 获取新增表单校验key
    closeYes: true,
    routParameters: {},
    portListShow: {},
    portList: [],
    // 公司全部人员下拉框值
    contactList: {},
    // 公司全部人员下拉框值
    contactValue: [],
    greyFunctions: [], // 灰度控制列表
    AONIcustSort: [], // 奥尼客户归类
    currencyShow: {},
    currency: [],
    // 系统后端域名前缀
    domain: {},
    unitList: []
}
