<template>
    <div class="SelectCountry" v-if="isShow">
        <div :class="{'Header': true, 'app-header': getIsApp()}">
            <span class="back" @click="close()">
                <i class="iconfont icon-page-left"></i>
            </span>
            <!-- 选择国家 -->
            {{ $t('mx_mobile.Client.1585625535843')}}
        </div>
        <!-- <div > -->
        <div class="list1">
            <search-bar v-model="value" @keyup.enter.native="toSearch" placeholder="搜索"></search-bar>
        </div>
        <div class="contenClass">
            <van-radio-group v-model="radio">
                <ul class="list">
                    <li v-for="(item,index) in listOption" :key="index">
                        <span>{{item.text}}</span>
                        <div class="chekedClass" @click.stop>
                            <van-radio :name="item.value"></van-radio>
                        </div>
                    </li>
                </ul>
            </van-radio-group>
        </div>
        <div class="footClass" @click="submit()">
            <!-- 完成 -->
            {{$t('mx_mobile.Client.1585286721404')}}
        </div>
    </div>
</template>

<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import selectCountryEvent from './event'
import { getIsApp } from '@/libs/utils'
export default {
    name: 'SelectCountry',
    data() {
        return {
            isShow: false,
            optionsList: [],
            listOption: [],
            value: '',
            radio: ''
        }
    },
    created() {
        selectCountryEvent.onChange(({ show, defaultValue, options }) => {
            this.isShow = show
            this.optionsList = this.listOption = options
            if (defaultValue && defaultValue.value) {
                this.radio = defaultValue.value
            }
        })
    },
    methods: {
        getIsApp,
        close() {
            selectCountryEvent.close()
        },
        toSearch() {
            let list = this.optionsList.filter(ele => ele.text.includes(this.value))
            this.listOption = list
        },
        submit() {
            let item = {
                text: '',
                value: ''
            }
            if (this.radio) {
                let list = this.optionsList.filter(ele => ele.value == this.radio)
                item = list[0]
            }
            selectCountryEvent.select(item)
            // this.$emit('closeSelect', item)
        }

    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
@import "./index.less";

.zh-cn .SelectCountry .Header.app-header,
.zh-tw .SelectCountry .Header.app-header {
    background: white;
    color: black;
}
</style>
