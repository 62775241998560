<template>
    <div id="app">
        <public-controls v-show="showHeader" class="appHeader"></public-controls>
        <div class="appBody" v-if="dataInitOk">
            <keep-alive>
                <router-view v-if="!$route.meta.noKeepAlive"></router-view>
            </keep-alive>
            <router-view v-if="$route.meta.noKeepAlive"></router-view>
        </div>
        <foot-nav v-if="$route.meta.hasTabBar && !getIsApp()" class="appFooter"></foot-nav>
        <utils></utils>

        <!-- 高德地图 -->
        <remote-js src="//webapi.amap.com/maps?v=1.4.11&key=18629938026b416e1a95925deba8f82f"></remote-js>
    </div>
</template>

<script>
import VConsole from 'vconsole'
import { mapMutations, mapActions } from 'vuex'
import PublicControls from './components/PublicControls/index'
import { getItem, getIsApp } from '@/libs/utils.js'
import Utils from './components/Utils/index'
import hybrid from '@/libs/hybrid'
import { getQueryString } from '@/libs/utils'

export default {
    name: 'app',
    data() {
        return {
            dataInitOk: false,
            showHeader: !(getQueryString('showHeader') === '0')
        }
    },
    created() {
        window.addEventListener('popstate', () => {
            console.log('goNative111', this.$route.path)
            if (this.$route.path.indexOf('/client/detail/') > -1 || this.$route.path.indexOf('/client/strange') > -1) {
                hybrid.goNative()
            }
        })
        const isProd = process.env.NODE_ENV === 'production'
        // eslint-disable-next-line
        if (!isProd && getIsApp()) { const vConsole = new VConsole() }
    },
    mounted() {
        // 设置默认语言
        const lang = getItem('lang')
        this.g_setLanguage((lang === 'en' ? lang : 0) || 'zh-cn')
        // console.log(this.$t('mxpcweb.login.1528183814659')) // 语言测试
        this.funInit()
        this.dataInit()
    },
    methods: {
        getIsApp,
        ...mapMutations({
            set_companie: 'SET_COMPANIE',
            set_userInfo: 'SET_USERINFO'
        }),
        ...mapActions([
            'setContactList',
            'setGreyFunctions'
        ]),
        funInit() {
            const { getItem, removeItem } = this.Global.utils
            const isFumaApp = getItem('isFumaApp')
            window.reLogin = () => {
                removeItem('accessToken')
                removeItem('individualAccessToken')
                removeItem('companie')
                if (isFumaApp) {
                    let message = JSON.stringify({ type: 'tokenInvalid' })
                    window.ReactNativeWebView
                        ? window.ReactNativeWebView.postMessage(message)
                        : window.postMessage(message)
                } else if (getIsApp()) {
                    hybrid.logout()
                } else {
                    this.$toast.fail('令牌过期')
                    this.$router.push('/login')
                }
            }
            let _open = window.open
            window.open = function (params) {
                let url
                if (typeof params === 'object') {
                    url = params.url
                } else {
                    url = params
                    params = {}
                }
                if (getIsApp()) {
                    delete params.url
                    delete params.type
                    let message = JSON.stringify({ type: 'openLink', data: url, ...params })
                    console.log(message)
                    hybrid.openWebPage(message)
                    return
                }

                _open.apply(null, arguments)
            }
        },
        dataInit() {
            const { getItem } = this.Global.utils
            const companie = getItem('companie')
            const userInfo = getItem('userInfo')
            if (companie) {
                this.set_companie(JSON.parse(companie))
                let companieObj = JSON.parse(companie || '{}')
                this.setGreyFunctions({ cId: companieObj.cId })
            }
            if (userInfo) {
                this.set_userInfo(JSON.parse(userInfo))
            }
            this.dataInitOk = true
            this.setContactList()
        }
    },
    components: {
        'public-controls': PublicControls,
        'utils': Utils,
        'remote-js': {
            render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src } })
            },
            props: {
                src: { type: String, required: true }
            }
        }
    }
}
</script>

<style lang="less">
@import "~@/assets/less/reset.less";
</style>
<style lang="less" scoped>
#app {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    background: rgba(244, 244, 244, 1);
    flex-direction: column;
}
.appHeader {
    .height(44px);
}
.appBody {
    flex: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
}
.appFooter {
    .height(50px);
}
</style>
