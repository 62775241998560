<template>
    <div class="nodata">
        <img v-if="img" :src="getSrc()">
        <div>{{title}}</div>
    </div>
</template>
<script>
export default {
    name: 'nodata',
    props: {
        img: {
            type: String,
            default: 'noData'
        },
        title: {
            type: String,
            default: function fc() { return this.$t('mx_mobile.Doc.1585191499748') } // '暂无数据'
        }
    },
    data() {
        return {
        }
    },
    methods: {
        getSrc() {
            return '/images/noData/' + this.img + '.png'
        }
    },
    components: {

    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.nodata {
    position: relative;
    text-align: center;
    padding: 30px 0 10px;
    color: #909399;
    // font-size: 14px;
    > img {
        max-width: 150px;
    }
    > div {
        margin: 10px 0;
    }
}
</style>
