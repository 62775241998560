<template>
    <div class="SelectCountry" v-if="isShow">
        <div :class="{'Header': true, 'app-header': getIsApp()}">
            <span class="back" @click="close()">
                <i class="iconfont icon-page-left"></i>
            </span>
            <!-- 选择商品 -->
            {{$t('mx_mobile.common.1665975979339')}}
        </div>
        <div class="list1">
            <!-- 搜索 -->
            <search-bar v-model="value" @keyup.enter.native="toSearch" :placeholder="$t('mx_mobile.Client.1585115144101')"></search-bar>
        </div>
        <div class="contenClass">
            <van-radio-group v-model="radio">
                <ul class="list">
                    <li v-for="(item,index) in listOption" :key="index">
                        <span>{{item.spuName}}</span>
                        <div class="chekedClass" @click.stop>
                            <van-radio :name="item.spuId"></van-radio>
                        </div>
                    </li>
                </ul>
            </van-radio-group>
        </div>
        <div class="footClass" @click="submit()">
            <!-- 完成 -->
            {{$t('mx_mobile.Client.1585286721404')}}
        </div>
    </div>
</template>

<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import selectCountryEvent from './event'
import { getIsApp } from '@/libs/utils'
export default {
    name: 'SelectCountry',
    data() {
        return {
            isShow: false,
            optionsList: [],
            listOption: [],
            value: '',
            radio: ''
        }
    },
    created() {
        selectCountryEvent.onChange(({ show, defaultValue, options, radio, value }) => {
            this.isShow = show
            this.optionsList = this.listOption = options
            this.radio = radio
            this.value = value
            if (defaultValue && defaultValue.spuId) {
                this.radio = defaultValue.spuId
            }
        })
    },
    methods: {
        getIsApp,
        close() {
            selectCountryEvent.close()
        },
        toSearch() {
            let data = {
                keywords: this.value,
                type: 'search'
            }
            selectCountryEvent.seatchList(data)
        },
        submit() {
            let item
            if (this.radio) {
                let list = this.optionsList.filter(ele => ele.spuId == this.radio)
                item = list[0]
            }
            selectCountryEvent.select(item)
        }

    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
@import "./index.less";

.zh-cn .SelectCountry .Header.app-header,
.zh-tw .SelectCountry .Header.app-header {
    background: white;
    color: black;
}
</style>
