import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
import modules from './modules'
// import createLogger from 'vuex/dist/logger'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
export const store = new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules,
    strict: debug
    // plugins: debug ? [createLogger()] : []
})
