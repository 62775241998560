export function Const(state) {
  return state.const
}

// export const
// const = state => state.const

export const boxIndex = state => state.boxIndex

export const boxAarry = state => state.boxAarry

export const boxMid = state => state.boxMid

export const boxName = state => state.boxName

export const actionTarget = state => state.actionTarget

export const currentAddress = state => state.currentAddress

export const currentCtid = state => state.currentCtid
