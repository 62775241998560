<template>
    <div class="SelectCountry" v-if="isShow">
        <div :class="{'Header': true, 'app-header': getIsApp()}">
            <span class="back" @click="close()">
                <i class="iconfont icon-page-left"></i>
            </span>
            选择背胶方式
        </div>
        <!-- <div > -->
        <div class="list1">
            <search-bar v-model="value" @keyup.enter.native="toSearch" placeholder="搜索"></search-bar>
        </div>
        <div class="contenClass" ref="list">
            <van-radio-group v-model="radio">
                <ul class="list">
                    <li v-for="(item,index) in listOption" :key="index">
                        <span>{{item.text}}</span>
                        <div class="chekedClass" @click.stop>
                            <van-radio :name="item.value"></van-radio>
                        </div>
                    </li>
                </ul>
            </van-radio-group>
        </div>
        <div class="footClass" @click="submit()">
            <!-- 完成 -->
            {{$t('mx_mobile.Client.1585286721404')}}
        </div>
    </div>
</template>

<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import selectBackGlueModeEvent from './event'
import { getIsApp } from '@/libs/utils'
import BScroll from 'better-scroll'
export default {
    name: 'SelectBackGlueMode',
    data() {
        return {
            isShow: false,
            optionsList: [],
            listOption: [],
            value: '',
            radio: '',
            size: 200
        }
    },
    created() {
        selectBackGlueModeEvent.onChange(({ show, defaultValue, options }) => {
            this.isShow = show
            this.optionsList = options
            this.listOption = this.optionsList.slice(0, 200)
            if (defaultValue && defaultValue.value) {
                this.radio = defaultValue.value
            }
            this.$nextTick(() => {
                this.initScroll()
            })
        })
    },
    destroyed() {
        this.$refs.scroll && this.$refs.scroll.destroy()
    },
    methods: {
        initScroll() {
            if (!this.$refs.list) {
                return
            }
            this.scroll = new BScroll(this.$refs.list, {
                scrollY: true,
                click: true
            })
            this.scroll.on('scrollEnd', () => {
                if (this.scroll.y <= (this.scroll.maxScrollY + 50)) {
                    this.getListOptionData()
                }
            })
        },
        getListOptionData () {
            this.size += 200
            if (this.value) {
                this.toSearch()
                return
            }
            this.listOption = this.optionsList.slice(0, this.size)
        },
        getIsApp,
        close() {
            this.size = 200
            selectBackGlueModeEvent.close()
        },
        toSearch() {
            let list = this.optionsList.filter((ele) => ele.text.toLocaleLowerCase().includes(this.value.toLocaleLowerCase()))
            this.listOption = list.slice(0, this.size)
        },
        submit() {
            let item = {
                text: '',
                value: ''
            }
            if (this.radio) {
                let list = this.optionsList.filter(
                    (ele) => ele.value == this.radio
                )
                item = list[0]
            }
            selectBackGlueModeEvent.select(item)
            // this.$emit('closeSelect', item)
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
@import "./index.less";

.zh-cn .SelectCountry .Header.app-header,
.zh-tw .SelectCountry .Header.app-header {
    background: white;
    color: black;
}
</style>
