export default {
    UniversalInterface: {
        domain: 'UniversalInterface/domain' // 获取node端域名前缀
    },
    timezone: '/pcapi/v2/dictionary/timezone',
    dingding: {
        getuserinfo: 'dingding/getuserinfo', // code换取用户身份
        getUser: 'dingding/getUser', // userid获取用户详情
        getSpace: 'dingding/getSpace', // 获取自定义空间
        grantSpace: 'dingding/grantSpace'
    },
    // 代理后端请求
    v2: {
        download: 'v2/download/', // 下载
        // 通用
        verifySMS: 'v2/verifySMS', // 发送校验短信验证码
        verifyemail: 'v2/verifyemail', // 发送校验邮箱验证码
        checkValid: 'v2/checkValid/', // 有效性验证（企业、账号）
        navigator_get: 'v2/navigator/get', // 获取导航列表
        timestamp: 'v2/timestamp', // 获取服务器linux时间戳
        dictionary: 'v2/dictionary/', // 获取系统基础信息
        area: 'v2/area/', // 获取行政区划信息
        accountDropList_get: 'v2/accountDropList/get', // 获取人员/部门下拉框
        sysDefault_get: 'v2/sysDefault/get', // 获取系统默认配置信息
        controlTypeV2: 'v2/controlEX/controlTypeV2',
        document_quotation_post: 'v2/document/quotation/post', // 新增单据
        // 邮件
        mails_fastTextGroup_deleteFastTextGroup: 'v2/mails/fastTextGroup/deleteFastTextGroup', // 删除快速文本组
        mails_fastTextGroup_getFastTextGroupList: 'v2/mails/fastTextGroup/getFastTextGroupList', // 获取快速文本组列表
        mails_fastTextGroup_addFastTextGroup: 'v2/mails/fastTextGroup/addFastTextGroup', // 新增快速文本组
        mails_fastText_addFastText: 'v2/mails/fastText/addFastText', // 新增快速文本
        mails_fastText_deleteFastText: 'v2/mails/fastText/deleteFastText', // 删除快速文本
        mails_fastText_getFastTextList: 'v2/mails/fastText/getFastTextList', // 获取快速文本列表
        mails_account: 'v2/mails/account', // 邮箱账号相关=>添加账号,修改已添加的邮箱账号,删除已添加的邮箱账号,获取已添加的邮箱账号列,获取已添加账户信息
        mails_mailOptions_getOptions: 'v2/mails/mailOptions/getOptions', // 邮箱服务器配置
        mails_mailOptions_updateOptions: 'v2/mails/mailOptions/updateOptions', // 更新邮件设置列表
        mails_mailbox: 'v2/mails/mailbox', // 邮件文件夹相关=>添加文件夹,修改文件夹,获取邮件文件夹信息,清空回收站文件夹
        mails_mailboxes: 'v2/mails/mailboxes', // 获取邮件文件夹列表
        mails_maillist: 'v2/mails/maillist', // 邮件列表相关
        mails_search: 'v2/mails/search', // 邮件高级搜索
        mails_fastSearch: 'v2/mails/fastSearch', // 邮件快速搜索
        mails_mailscount: 'v2/mails/mailscount', // 获取邮件目录统计信息
        mails_mail: 'v2/mails/mail', // 邮件相关操作=>邮件更新操作,获取单个邮件信息,邮件彻底删除,
        mails_download: 'v2/mails/download', // 邮件文件下载
        mails_searchField_get: 'v2/mails/searchField/get', // 搜索字段配置=>获取邮件搜索字段
        mails_searchField_put: 'v2/mails/searchField/put', // 修改搜索字段
        mails_searchField_add: 'v2/mails/searchField/add', // 添加(删除)搜索字段
        mails_accountStatus: 'v2/mails/accountStatus', // 获取邮件接收状态
        mails_accounts: 'v2/mails/accounts', // 获取邮件接收状态
        mails_publicRules: 'v2/mails/publicRules', // 分发规则列表
        mails_fileTransfer: 'v2/mails/fileTransfer', // 钉盘链接转换
        // 单据框架
        document_formKey: 'v2/document/formKey', // formKey
        customers_add: 'v2/customers/add', // 新增客户
        customers_get: 'v2/customers/get', // 获取客户列表, 获取客户详情
        document_quickSearch_get: 'v2/document/quickSearch/get', // 单据列表快速查询
        customers_put: 'v2/customers/put', // 修改客户（基础信息）
        customers_status_put: 'v2/customers/status/put', // 修改客户（特殊信息）,修改联系人（特殊信息）
        customerCheck_get: 'v2/customerCheck/get', // 客户查重
        customerMerge_do: 'v2/customerMerge/do', // 客户合并
        custContacts_add: 'v2/custContacts/add', // 客户联系人=>新增联系人
        custContacts_get: 'v2/custContacts/get', // 获取联系人列表,获取联系人详情
        custContacts_put: 'v2/custContacts/put', // 修改联系人（基础信息）
        socialType_get: 'v2/socialType/get', // 获取社交类型
        custTracks_add: 'v2/custTracks/add', // 客户跟进=>新增客户跟进
        custTracks_get: 'v2/custTracks/get', // 获取客户跟进列表,获取客户跟进详情
        custTracks_put: 'v2/custTracks/put', // 修改跟进（基础信息）
        customerWithContact_add: 'v2/customerWithContact/add', // 修改跟进（基础信息）
        module_get: 'v2/module/get', // 获取模块列表及对应的操
        fieldShow_get: 'v2/fieldShow/get', // 获取业务字段展示
        moduleStruct: 'v2/moduleStruct/', // 获取模块结构
        fieldShow_custCheck_do: 'v2/fieldShow/custCheck/do', // 获取客户查重字段展示,修改相关字段
        sysBoxValue_get: 'v2/sysBoxValue/get', // 获取下拉框值
        fieldDetails_get: 'v2/fieldDetails/get', // 字段操作相关=>获取相关字段
        fieldDetails_put: 'v2/fieldDetails/put', // 修改相关字段
        document_generalOperate_add: 'v2/document/generalOperate/add', // 单据新增
        document_generalOperate_get: 'v2/document/generalOperate/get', // 获取单据列表,获取单据详情
        document_generalOperate_put: 'v2/document/generalOperate/put', // 修改单据
        document_generalOperate_delete: 'v2/document/generalOperate/delete', // 单据删除
        document_newStructSearch_do: 'v2/document/newStructSearch/do', // 客户列表
        moduleOptSet_get: 'v2/moduleOptSet/get', // 获取单据操作项
        document_rightCheck_do: 'v2/document/rightCheck/do', // 判断是否有权限操作
        document_viewset_get: 'v2/document/viewset/get', // 获取单据视图布局
        document_operate_listOpt_put: 'v2/document/operate/listOpt/put', // 批量修改
        document_operate_detailOpt_put: 'v2/document/operate/detailOpt/put', // 关注,取消关注
        document_config_get: 'v2/document/config/get', // 字段单独检验重复
        customerIntegrity_do: 'v2/customerIntegrity/do', // 获取客户资料完整度详情
        document_importExport_do: 'v2/document/importExport/do', // 导入（下载模板，获取模板字段，单据导入）
        document_feedback: 'v2/document/feedback', // 新增我的反馈
        reportManage: 'v2/reportManage', // 新增我的反馈
        // 单据和邮箱公用接口
        label_add: 'v2/label/add', // 新增标签
        label_delete: 'v2/label/delete', // 删除标签
        label_put: 'v2/label/put', // 修改标签
        label_get: 'v2/label/get', // 获取标签列表,获取单个标签信息
        billLabel_put: 'v2/billLabel/put', // 单据(邮件)更新标签
        billComment_add: 'v2/billComment/add', // 新增批注
        billComment_update: 'v2/billComment/update', // 删除批注
        billComment_get: 'v2/billComment/get', // 修改批注
        // 系统设置
        operateLog_query: 'v2/operateLog/query', // 获取登录日志,使用个人令牌
        behaviorLog_get: 'v2/behaviorLog/get', // 获取行为日志
        frontLog_add: 'v2/frontLog/add', // 前端日志记录
        auth: 'v2/auth', // 登录
        dinglogin: 'v2/dinglogin/', // 钉钉扫码登录
        loginEnterprise: 'v2/loginEnterprise', // 登入进企业记录行为
        department: 'v2/department/', // 获取部门信息
        department_add: 'v2/department/add', // 新增部门
        department_put: 'v2/department/put', // 修改部门信息,同级部门移动
        department_delete: 'v2/department/delete', // 删除部门
        company_add: 'v2/company/add', // 注册企业,个人令牌
        company_query: 'v2/company/query', // 获取企业基础信息
        company_put: 'v2/company/put', // 修改企业基础信息
        companysetting_get: 'v2/companysetting/get', // 获取企业配置信息,全员重置密码
        companysetting_update: 'v2/companysetting/update', // 修改企业配置信息
        simpleCompanyInfo: 'v2/simpleCompanyInfo/', // 根据二级域名获取简单企
        companyWhiteList_add: 'v2/companyWhiteList/add', // 修改企业白名单
        companyWhiteList_get: 'v2/companyWhiteList/get', // 获取企业白名单
        account_add: 'v2/account/add', // 注册个人账号
        resetpwd: 'v2/resetpwd', // 修改密码、重置密码
        account_put: 'v2/account/put', // 修改账号信息,个人令牌
        account_update: 'v2/account/update', // 单人或多人部门调整
        account_get: 'v2/account/get', // 查询个人账户信息,个人令牌,多条件获取企业人员信息
        account_query: 'v2/account/query', // 查询企业下的个人账户信息，查询企业下的所有账户信息
        account: 'v2/account/', // 根据部门获取个人信息列表
        personalsetting_get: 'v2/personalsetting/get', // 获取个人配置信息
        personalsetting_put: 'v2/personalsetting/put', // 修改个人配置信息
        invitation: 'v2/invitation', // 生成邀请链接邀请成员
        accountlist: 'v2/accountlist', // 批量添加账号
        contact_update: 'v2/contact/update', // 修改个人账号在某企业中
        contact_delete: 'v2/contact/delete', // 删除账号和企业的关联
        contact_add: 'v2/contact/add', // 添加人员进企业
        contact_do: 'v2/contact/do', // 添加人员进企业
        messenger_post: 'v2/messenger/post', // 新建提醒（日程）
        message_get: 'v2/message/get', // 获取提醒列表
        message_put: 'v2/message/put', // 修改提醒
        messenger_put: 'v2/messenger/put', // 修改日程
        messenger_get: 'v2/messenger/get', // 获取日程列表
        messenger_delete: 'v2/messenger/delete', // 删除日程
        roles: 'v2/roles/', // 获取角色
        roles_post: 'v2/roles/post', // 新增/复制角色
        roles_put: 'v2/roles/put', // 编辑角色
        roles_delete: 'v2/roles/delete', // 角色删除
        roles_contacts: 'v2/roles/contacts/', // 获取角色人员,获取人员的角色数
        roles_contacts_post: 'v2/roles/contacts/post', // 添加角色人员
        roles_contacts_delete: 'v2/roles/contacts/delete', // 删除角色人员
        permissions_get: 'v2/permissions/get', // 获取权限页,查询权限项目定义,查询权限分配
        permissions_put: 'v2/permissions/put', // 查询权限分配
        permissions_do: 'v2/permissions/do', // 权限字段限制查询
        rolesField_do: 'v2/rolesField/do', // 权限字段限制查询

        customerCooperate_do: 'v2/customerCooperate/do', // 新增共享协作人
        dictNavigator_get: 'v2/dictNavigator/get', // 获取业务字典导航
        dictionarys_add: 'v2/dictionarys/add', // 字典新增
        dictionarys_update: 'v2/dictionarys/update', // 字典修改
        dictionarys_query: 'v2/dictionarys/query', // 字典查询
        app_get: 'v2/app/get', // 获取应用中心列表
        appSet_doPut: 'v2/appSet/doPut', // 获取应用中心列表
        bizNavigator_get: 'v2/bizNavigator/get', // 业务字段配置导航
        bizField_add: 'v2/bizField/add', // 新增字段
        bizField_query: 'v2/bizField/query', // 业务字段相关查询接口
        bizField_update: 'v2/bizField/update', // 修改(编辑)字段
        seasSetting_set_do: 'v2/seasSetting/set/do', // 获取及修改公海设置
        seasSetting_seas_add: 'v2/seasSetting/seas/add', // 新建公海规则
        seasSetting_seas_put: 'v2/seasSetting/seas/put', // 修改公海规则
        seasSetting_seas_delete: 'v2/seasSetting/seas/delete', // 删除公海规则
        seasSetting_seas_get: 'v2/seasSetting/seas/get', // 获取公海规则列表,获取公海规则详情,获取自动放入规则条件筛
        seasSetting_custLimit_get: 'v2/seasSetting/custLimit/get', // 获取$条件筛选,获取$列表,获取$详情
        seasSetting_custLimit_add: 'v2/seasSetting/custLimit/add', // 新建$
        seasSetting_custLimit_put: 'v2/seasSetting/custLimit/put', // 修改$
        seasSetting_custLimit_delete: 'v2/seasSetting/custLimit/delete', // 删除$
        textDict_get: 'v2/textDict/get', // 多行文本
        homeQuickOptSet_do: 'v2/homeQuickOptSet/do',
        // 数据统计接口
        stat_panel_get: 'v2/stat/pers/panel/get', // MX工作台个人面板信息接
        stat_custDist_get: 'v2/stat/pers/custDist/get', // MX工作台客户分布面板数
        stat_pers_get: 'v2/stat/pers/get', // MX工作台简报数据接口,MX工作台提醒小秘书数据,MX工作台活跃度统计接口,MX工作台月走势图接口
        stat_rank_get: 'v2/stat/pers/rank/get', // MX工作台部门内排行接口
        stat_pers_trackDetail_get: 'v2/stat/pers/trackDetail/get', // 客户跟进动态统计数接口
        stat_dept_panel_get: 'v2/stat/dept/panel/get', // 部门面板初始化信息接口
        stat_dept_briefReport: 'v2/stat/dept/briefReport/get', // 部门工作简报接口
        stat_dept_rank_get: 'v2/stat/dept/rank/get', // 部门排行接口
        stat_dept_custSummarize_get: 'v2/stat/dept/custSummarize/get', // 部门客户概述统计接口
        stat_dept_custDist_get: 'v2/stat/dept/custDist/get', // 部门客户分布统计接口
        stat_dept_monthlyChart_get: 'v2/stat/dept/monthlyChart/get', // 部门客户走势图统计接口
        stat_dept_activity_get: 'v2/stat/dept/activity/get', // 部门活跃度统计接口
        stat_dept_actCustDist_get: 'v2/stat/dept/actCustDist/get', // 部门业务员客户分布统计接口
        stat_init_put: 'v2/stat/init/put', // 统计数据初始化接口

        /* 文档与知识库 */
        folders_foldersTree: 'v2/folders/foldersTree', // 树目录 客户附件文件夹

        folders_files: 'v2/folders/files', // 文件相关的操作
        folders_inFileSys: 'v2/folders/inFileSys', // 本地文件服务器上传接口
        folders_folderRights: 'v2/folders/folderRights', //  权限相关的接口
        folders_fileRights: 'v2/folders/fileRights', // 文件操作权限查询接口
        folders_fileLock: 'v2/folders/fileLock', // 文件迁入迁出相关的接口
        folders_fileVersion: 'v2/folders/fileVersion', // 文件历史版本
        folders_downloadURL: 'v2/folders/downloadURL', // 文件获取下载链接接口
        folders_fileDownloadV2: 'v2/folders/fileDownloadV2', // 文件下载
        folders_newFile: 'v2/folders/newFile', // 修订后的文件回显接口
        folders_recycleBinFile: 'v2/folders/recycleBinFile', // 回收站相关的接口
        folders_file_content: 'v2/folders/file/content', // 文本内容获取
        folders_preViewImage: 'v2/folders/preViewImage', // 视频封面上传
        folders_zipDownloadFiles: 'v2/folders/zipDownloadFiles', // java处理的多文件下载
        folders_mailAttachmentPaser: 'v2/folders/mailAttachmentPaser', // 邮件附件预览时内容解析
        doc_preView: 'v2/preView/', // 兼容预览
        folders_moveFiles: 'v2/folders/moveFiles', // 文档系统文件转存入文件服务器接口
        folders_foldersTreeWithRight: 'v2/folders/foldersTreeWithRight', // 根据操作权限获取对应许可列表数据接
        folders_transferFiles: 'v2/folders/transferFiles', // 其他模块文件转存文档接口
        folders_copyFile: 'v2/folders/copyFile', // 复制文件到指定文件夹
        folders_changeFolder: 'v2/folders/changeFolder', // 移动文件到指定文件夹
        folders_dingPan: 'v2/folders/dingPan', // 钉钉 存文件
        customerNearby_do: 'v2/customerNearby/do', // 接口名称: 获取周边客户

        /* 单据附件，文件上传OSS */
        docFile_get: 'v2/docFile/get', // 单据附件列表
        fileDownLoad_get: 'v2/fileDownLoad/get', // 单据附件的下载链接的获取
        docFile_put: 'v2/docFile/put', // 单据附件删除
        folders_fileTask: 'v2/folders/fileTask', // 单据附件删除
        group_get: 'v2/group/get', // 客户附件分组列表
        mails_contAttachments: 'v2/mails/contAttachments', // 接口名称: 获取附件接口 待扩展 (pc端接口，用于查看邮件附件)
        // folders_foldersTree: 'v2/folders/foldersTree', // 客户附件文件夹

        /* 工单 */
        workOrder_add: 'v2/workOrder/add',
        workOrder_get: 'v2/workOrder/get',
        workOrder_put: 'v2/workOrder/put',
        /* 工单记录 */
        statusChange_add: 'v2/statusChange/add',
        statusChange_get: 'v2/statusChange/get',
        // 评价
        workEvaluate_add: 'v2/workEvaluate/add',

        // 系统更新通知相关
        sysNotice_add: 'v2/sysNotice/add',
        sysNotice_get: 'v2/sysNotice/get',
        sysNotice_put: 'v2/sysNotice/put',

        // 钉钉关系绑定
        dingtalk_auth_check: 'v2/dingtalk/auth/check',
        dingtalk_bind_add: 'v2/dingtalk/bind/add',
        dingtalk_relationship: 'v2/dingtalk/relationship/update', // 授权
        payCenter_getAuth: 'v2/payCenter/getAuth', // 获取授权信息
        getAuthInfo: 'v2/payCenter/getAuth', // 获取授权信息

        priceCount: 'v2/priceCount', // 接口名称: 价格计算
        saleOrder: 'v2/saleOrder', // 接口名称: 业务员新增的订单
        mxOrder_mxOrderList: 'v2/mxOrder/mxOrderList', // 接口名称: 业务员新增的订单
        manageFlag: 'v2/manageFlag', // 查是否是孚盟 =》 管理企业
        companyOrder: 'v2/companyOrder', // 接口名称: 公司内部订单列表
        payCenter_onlineOrder: 'v2/payCenter/onlineOrder', // 接口名称: 订单删除
        activity: 'v2/activity', // 接口名称: 活动获取
        mxOrder_mxOrder: 'v2/mxOrder/mxOrder', // 接口名称: 订单详情
        isBill: 'v2/bill', // 接口名称: 是否存在未处理订单
        offCost: 'v2/offCost', // 接口名称: 优惠金额体现
        mxorderDetail: 'v2/mxorderDetail', // 接口名称: 详情页面（带权限）
        allPackages: 'v2/allPackages', // 全部套餐
        mailWatcher_statistics: 'v2/mailWatcher/statistics', // 获取实时统计数据
        mailWatcher_abNormalList: 'v2/mailWatcher/abNormalList', // 异常任务列表
        mailWatcher_taskStatus: 'v2/mailWatcher/taskStatus',
        mailWatcher_taskOperateLog: 'v2/mailWatcher/taskOperateLog', // 任务操作记录
        mailWatcher_delayMessgaes: 'v2/mailWatcher/delayMessgaes', // 延时邮件列表
        mailWatcher_recentMails: 'v2/mailWatcher/recentMails/', // 最近邮件接口
        mailWatcher_abnormalMessgaes: 'v2/mailWatcher/abnormalMessgaes', // 异常邮件列表
        greyFunctions: 'v2/operations/greyFunctions', // 获取查询灰度功能
        AONIcustSort: 'v2/AONIcustSort',
        custArea: 'v2/custArea', // 区域新增
        rateManage_curCode: 'v2/rateManage/curCode',
        document_product: 'v2/document/product', // 获取商品列表
        document_product_category: 'v2/document/product/category', // 商品大类查询
        internal_contact_query: 'v2/internalContact/Query', // 查询内部联系人
        thirdparty_grassSilkPrice: 'v2/thirdparty/grassSilkPrice', // 共创-草丝列表
        thirdparty_calculationDependDocument: 'v2/thirdparty/calculationDependDocument', // 共创-算价单依赖单据查询
        document_quotation_get: 'v2/document/quotation/get', // 获取详情
        getAuthorizationModuleCode: 'v2/app/getAuthorizationModuleCode' // 获取详情
    },
    Subordinate: { getTreeMenu: 'Subordinate/getTreeMenu' },
    Mail: {
        getMenuNavList: 'Mail/menuNavList',
        mailsMailscountGet: 'Mails/home/mailsMailscountGet', // 获取邮件文件夹列表
        mailsMailboxesGet: 'Mails/home/mailsMailboxesGet', // 获取邮件文件夹列表
        mailsMailboxPost: 'Mails/home/mailsMailboxPost', // 添加文件夹
        mailsMailboxPut: 'Mails/home/mailsMailboxPut', // 修改文件夹
        mailsMailboxGet: 'Mails/home/mailsMailboxGet', // 获取邮件文件夹信息
        mailsMailboxDelete: 'Mails/home/mailsMailboxDelete', // 清空回收站文件夹
        getMailsFastSearch: 'Mails/home/getMailsFastSearch', // 邮件快速搜索
        mailBoxRulePut: 'Mails/home/mailBoxRulePut', // 文件夹规则修改/新建
        mailBoxRuleDelete: 'Mails/home/mailBoxRuleDelete', // 删除文件夹规则
        // 标签

        maillabelPost: 'Mails/home/maillabelPost', // 新增标签
        maillabelDelete: 'Mails/home/maillabelDelete', // 删除标签
        MaillabelPut: 'Mails/home/MaillabelPut', // 修改标签
        maillabelsGet: 'Mails/home/maillabelsGet', // 获取用户标签列表
        maillabelGet: 'Mails/home/maillabelGet', // 获取单个标签信息
        mailPut: 'Mails/home/mailPut', // 邮件设置标签
        // 邮件列表
        getMaillist: 'Mails/home/getMaillist', // 获取指定文件夹的邮件列表
        getTopmaillist: 'Mails/home/getTopmaillist', // 获取置顶邮件
        ManyMaillistPut: 'Mails/home/ManyMaillistPut', // 邮件更新操作
        getMailCurrent: 'Mails/home/getMailCurrent', // 获取指定邮件
        mailDelete: 'Mails/home/mailDelete', // 删除邮件
        getDownload: 'Mails/home/getDownload', // 邮件文件下载
        billLabelPut: 'Mails/home/billLabelPut', // 单据更新标签
        getAdvancedSearch: 'Mails/home/getAdvancedSearch', // 获取邮件搜索字段
        searchFieldPut: 'Mails/home/searchFieldPut', // 修改搜索字段
        searchFieldPost: 'Mails/home/searchFieldPost', // 添加(删除)搜索字段
        bulkUpdatePut: 'Mails/home/bulkUpdatePut', // 批量标记为已读
        getMailStatus: 'Mails/home/getMailStatus', // 邮件收件错误消息
        mailsSpamSetPost: 'Mails/home/mailsSpamSetPost', // 把邮件设置为垃圾邮件
        mailBoxTransferPost: 'Mails/home/mailBoxTransferPost', // 文件夹移交给
        mailMergePost: 'Mails/home/mailMergePost', // 归档
        getMailsManualRec: 'Mails/home/getMailsManualRec', // 收件
        mailsImportUrl: 'Mails/home/mailsImportUrl', // 邮件导入
        mailDistributePost: 'Mails/home/mailDistributePost', // 邮件内分发
        mailPublicMailDistPost: 'Mails/home/mailPublicMailDistPost', // 未分发
        getMailsMail: 'Mails/home/getMailsMail', // 获取单个邮件信息
        mailssavePost: 'Mails/home/mailssavePost', // 写邮件
        mailsrecipientsGet: 'Mails/home/mailsrecipientsGet', // 白名单联系人查询
        MailEditor: 'Mails/home/MailEditor', // 邮件编辑
        MailWrite: 'Mails/home/MailWrite', // 写邮件
        MailDraft: 'Mails/home/MailDraft', // 编辑草稿
        mailsTmplSelectGet: 'Mails/home/mailsTmplSelectGet', // 写，回复，转发模板选择
        MailWSwitchAccountite: 'Mails/home/MailWSwitchAccountite', // 切换账号
        mailsDeliveryStatusGet: 'Mails/home/mailsDeliveryStatusGet', // 邮件发送状态获取
        GetDeliveryStatus: 'Mails/home/GetDeliveryStatus',
        getSendMailAccount: 'Mails/home/getSendMailAccount', // 获取发送账号
        mailsSendReceiptPost: 'Mails/home/mailsSendReceiptPost', // 发送回执
        getSettingInformation: 'Mails/home/getSettingInformation', // 邮件设置
        DeleteTimeTask: 'Mails/home/DeleteTimeTask', // 邮件撤销发送
        // 黑名单
        BlackListGet: 'Mails/home/BlackListGet', // 获取黑名单列表
        BlackListPost: 'Mails/home/BlackListPost', // 加入黑名单
        BlackListDelete: 'Mails/home/BlackListDelete', // 移除黑名单
        // 白名单
        whitelistAdd: 'Mails/home/whitelistAdd', // 白名单添加
        whitelistGet: 'Mails/home/whitelistGet', // 获取白名单列表
        whitelistDelete: 'Mails/home/whitelistDelete', // 移除白名单

        GetNumberAccounts: 'Mails/home/GetNumberAccounts', // 获取账号数量
        GetPersonnels: 'Mails/home/GetPersonnels', // 获取当前用户有权限查看邮件的人员列表
        GetRightsCheck: 'Mails/home/GetRightsCheck', // 判断操作是否有权限接口
        mailsImport: 'Mails/home/mailsImport', // 导入邮件
        generalOperateGet: 'Mails/home/generalOperateGet',
        recipientCheckPost: 'Mails/home/recipientCheckPost', // 邮件接收者检查接口
        tracklogsGet: 'Mails/home/tracklogsGet', // 获取邮件阅读记录
        mailsMergePost: 'Mails/home/mailsMergePost', // 全部归并
        getMailsContacts: 'Mails/home/getMailsContacts' // 客户联系人获取
    },
    SystemSet: {
        enterpriseset: {
            // 企业设置
            enterpriseInf: 'SystemSet/enterpriseset/enterpriseInf', // 获取企业基础信息，获取企业配置信息
            saveEnterpriseInf: 'SystemSet/enterpriseset/saveEnterpriseInf', // 修改企业基础信息，修改企业配置信息
            areaInf: 'SystemSet/enterpriseset/areaInf' // 获取国家城市
        },
        systemparameter: {
            // 系统参数
            companysettingGet: 'SystemSet/systemparameter/companysettingGet', // 全员重置密码
            companyWhiteListGet: 'SystemSet/systemparameter/companyWhiteListGet', // 获取企业白名单
            companyWhiteListAdd: 'SystemSet/systemparameter/companyWhiteListAdd' // 修改企业白名单
        },
        personaldata: {
            // 个人资料
            accountIndividualInf: 'SystemSet/personaldata/accountIndividualInf', // 查询某企业下的个人账户信息
            accountPut: 'SystemSet/personaldata/accountPut' // 修改账号信息
        },
        accountsettings: {
            // 账号设置
            personalsettingGet: 'SystemSet/accountsettings/personalsettingGet', // 获取个人配置信息
            personalsettingPut: 'SystemSet/accountsettings/personalsettingPut', // 修改个人配置信息
            accountPut: 'SystemSet/accountsettings/accountPut' // 修改账号信息
        },
        applicationcenter: {
            // 应用中心
            appGet: 'SystemSet/applicationcenter/appGet' // 获取应用中心列表/get
        },
        groupstructure: {
            // 组织架构
            getTree: 'SystemSet/groupstructure/getTree', // 获取树菜单
            getTable: 'SystemSet/groupstructure/getTable', // 获取表格数据
            departmentAdd: 'SystemSet/groupstructure/departmentAdd', // 添加部门
            departmentPut: 'SystemSet/groupstructure/departmentPut', // 更新部门
            departmentDelete: 'SystemSet/groupstructure/departmentDelete', // 删除部门
            accountAdd: 'SystemSet/groupstructure/accountAdd', // 添加成员
            invitation: 'SystemSet/groupstructure/invitation', // 生成邀请链接邀请成员
            accountUpdate: 'SystemSet/groupstructure/accountUpdate', // 单人或多人部门调整
            operateLogQuery: 'SystemSet/groupstructure/operateLogQuery', // 批量注册账号日志
            accountlist: 'SystemSet/groupstructure/accountlist', // 批量注册账号
            downloadResults: 'SystemSet/groupstructure/downloadResults', // 创建文件并下载
            contactUpdate: 'SystemSet/groupstructure/contactUpdate', // 修改资料
            contactAdd: 'SystemSet/groupstructure/contactAdd', // 添加人员进企业
            quickAddAccount: 'SystemSet/groupstructure/quickAddAccount', // 快速添加成员
            disableEnable: 'SystemSet/groupstructure/disableEnable' // 禁用启用
        },
        rolemanag: {
            // 角色管理
            roles: 'SystemSet/rolemanag/roles', // 获取角色
            rolesPost: 'SystemSet/rolemanag/rolesPost', // 新增角色
            rolesPut: 'SystemSet/rolemanag/rolesPut', // 编辑角色
            rolesDelete: 'SystemSet/rolemanag/rolesDelete', // 删除角色
            rolesContacts: 'SystemSet/rolemanag/rolesContacts', // 获取角色人员/get
            rolesContactsPost: 'SystemSet/rolemanag/rolesContactsPost', // 添加角色人员/post
            rolesContactsDelete: 'SystemSet/rolemanag/rolesContactsDelete', // 删除角色人员/post
            permissionsGet: 'SystemSet/rolemanag/permissionsGet', // 查询权限项目定义,查询权限分配/get
            permissionsPut: 'SystemSet/rolemanag/permissionsPut', // 权限分配设置/post
            rolesId: 'SystemSet/rolemanag/rolesId' // 查询角色下告警人员/get
        },
        logaction: {
            // 日志和行为
            operateLogQuery: 'SystemSet/logaction/operateLogQuery', // 批量注册账号日志/获取登录日志/get
            behaviorLogGet: 'SystemSet/logaction/behaviorLogGet', // 获取行为日志/get
            frontLogAdd: 'SystemSet/logaction/frontLogAdd', // 前端日志记录/post
            moduleGet: 'SystemSet/logaction/moduleGet' // 获取模块列表及对应的操作/get
        },
        bizfield: {
            // 业务字段配置
            bizNavigatorGet: 'SystemSet/bizfield/bizNavigatorGet', // 业务字段配置导航
            bizFieldAdd: 'SystemSet/bizField/bizFieldAdd', // 新增字段
            bizFieldQuery: 'SystemSet/bizfield/bizFieldQuery', // 查询业务字段
            bizFieldUpdate: 'SystemSet/bizField/bizFieldUpdate', // 修改(编辑)字段
            controlTypeQuert: 'SystemSet/bizField/controlTypeQuert' // 获取字段控件列表
        },
        bizdict: {
            // 业务字典
            dictNavigatorGet: 'SystemSet/bizdict/dictNavigatorGet', // 获取业务字典导航/get
            dictionarysAdd: 'SystemSet/bizdict/dictionarysAdd', // 字典新增/ post
            dictionarysUpdate: 'SystemSet/bizdict/dictionarysUpdate', // 字典修改/ post
            dictionarysQuery: 'SystemSet/bizdict/dictionarysQuery' // 字典查询/ post
        },
        scheduleremind: {
            // 日程和提醒
            messengerPost: 'SystemSet/scheduleremind/messengerPost', // 新建提醒
            messageGet: 'SystemSet/scheduleremind/messageGet', // 获取提醒列表
            messagePut: 'SystemSet/scheduleremind/messagePut', // 修改提醒
            messengerGet: 'SystemSet/scheduleremind/messengerGet', // 获取日程列表
            messengerPut: 'SystemSet/scheduleremind/messengerPut', // 修改日程
            messengerDelete: 'SystemSet/scheduleremind/messengerDelete' // 删除日程
        },
        mailset: {
            // 邮件帐号
            mailaccount: {
                reviseAccount: 'SystemSet/mail/reviseAccount', // 修改已添加的邮箱账号
                reviseAccounts: 'SystemSet/mail/reviseAccounts', // 修改已添加的邮箱账号
                mailsAccount: 'SystemSet/mail/mailsAccount', // 添加账号
                accountAdd: 'SystemSet/mail/accountAdd', // 添加账号
                accounts: 'SystemSet/mail/accounts', // 获取用户绑定的所有邮箱账号
                accountsDelete: 'SystemSet/mail/accountsDelete', // 删除已添加的邮箱账号
                config: 'SystemSet/mail/config'
            },
            // 邮件设置
            setindex: {
                GetOptions: 'SystemSet/mail/mailOptionsGetOptions', // 获取邮件列表
                updateOptions: 'SystemSet/mail/updateOptions' // 邮件设置修改
            },
            // 模板
            template: {
                mailTemplatePost: 'Mails/home/mailTemplatePost', // 新增模板
                getMailsTemplate: 'Mails/home/getMailsTemplate', // 获取模板列表
                mailTmplSetPost: 'Mails/home/mailTmplSetPost', // 设置默认模板
                getMailsTmplSet: 'Mails/home/getMailsTmplSet', // 获取设置的默认模板
                mailTemplatePut: 'Mails/home/mailTemplatePut', // 修改模板
                mailTmplSetPut: 'Mails/home/mailTmplSetPut', // 修改默认模板
                mailTmplSetDelete: 'Mails/home/mailTmplSetDelete' // 删除模板
            },
            // 签名
            sign: {
                mailsSignaturePost: 'Mails/home/mailsSignaturePost', // 新建签名
                getMailsSignature: 'Mails/home/getMailsSignature', // 获取单个签名/签名列表
                mailsSignatureDelete: 'Mails/home/mailsSignatureDelete', // 删除签名
                mailsSignaturePut: 'Mails/home/mailsSignaturePut' // 修改签名
            },
            // 开发信
            developmentLetter: {
                mailsDevletterPost: 'Mails/home/mailsDevletterPost', // 新建开发信
                mailsDevletterGet: 'Mails/home/mailsDevletterGet', // 获取分组开发信/单个详情
                mailsDevletterGroupPost: 'Mails/home/mailsDevletterGroupPost', // 新建分组
                mailsDevletterGroupGet: 'Mails/home/mailsDevletterGroupGet', // 获取分组列表
                mailsDevletterGroupPut: 'Mails/home/mailsDevletterGroupPut', // 修改分组
                mailsDevletterGroupDelete: 'Mails/home/mailsDevletterGroupDelete', // 删除分组
                mailsDevletterPut: 'Mails/home/mailsDevletterPut', // 修改开发信
                mailsDevletterDelete: 'Mails/home/mailsDevletterDelete' // 删除开发信
            },
            // 快速开发文本
            fastText: {
                deleteFastTextGroup: 'Mails/home/deleteFastTextGroup', // 删除快速文本组
                getFastTextGroupList: 'Mails/home/getFastTextGroupList', // 获取快速文本组列表
                addFastTextGroup: 'Mails/home/addFastTextGroup', // 新增快速文本组
                updateFastTextGroup: 'Mails/home/modifyFastTextGroup', // 修改快速文本分组

                addFastText: 'Mails/home/addFastText', // 新增快速文本
                deleteFastText: 'Mails/home/deleteFastText', // 删除快速文本
                getFastTextList: 'Mails/home/getFastTextList', // 获取快速文本列表
                modifyFastText: 'Mails/home/modifyFastText' // 修改快速文本
            }
        },
        highseas: {
            // 公海设置
            seasSettingSeasAdd: 'SystemSet/highseas/seasSettingSeasAdd', // 新建自动放入规则,新建公海规则
            seasSettingSeasGet: 'SystemSet/highseas/seasSettingSeasGet', // 获取自动放入规则条件筛选,获取公海规则列表,获取公海规则详情
            seasSettingSeasDelete: 'SystemSet/highseas/seasSettingSeasDelete', // 删除公海规则
            seasSettingSeasPut: 'SystemSet/highseas/seasSettingSeasPut', // 修改公海规则
            custLimitGet: 'SystemSet/highseas/custLimitGet', // 获取客户最大保护规则$条件筛选
            custLimitAdd: 'SystemSet/highseas/custLimitAdd', // 新建客户最大保护规则
            custLimitPut: 'SystemSet/highseas/custLimitPut', // 修改客户最大保护规则
            custLimitDelete: 'SystemSet/highseas/custLimitDelete' // 删除客户最大保护规则
        }
    },
    client: {
        cardscan: {
            client_cardscan: 'client/cardscan/'
        }
    },
    v1: {
        paper_use: 'v1/daily/paper/used', // 写日志列表
        daily_paper: 'v1/daily/paper', // 创建日志模板
        paper_list: 'v1/daily/paper/list', // 日志列表
        paper_target: 'v1/daily/paper/index', // 获取角色+人员
        paper_name: 'v1/daily/paper/name', // 获取日志名字
        handle_paper: 'v1/daily/paper/submission', // 日志提交、暂存、删除
        paper_input_item: 'v1/daily/paper/input/item', // 获取日志输入项
        paper_view: 'v1/daily/paper/view', // 筛选日志
        paper_viewer: 'v1/daily/paper/viewer', // 获取查看日志人员
        paper_field: 'v1/daily/paper/field', // 获取日志模板字段/指标 - 报表
        paper_report: 'v1/daily/paper/report', // 日志报表
        export_report: 'v1/daily/paper/report/export', // 日志报表/daily/paper/report/export
        paper_send_user: 'v1/daily/paper/user', // 人员
        paper_index_detail: 'v1/daily/paper/index/jump', // 指标详情
        paper_upload_resource: 'v1/daily/paper/upload', // 销售日志上传图片及附件
        paper_unread_num: 'v1/daily/paper/unread/size' // 未读数量
    },
    dynamic: {
        // https://advapi.laifuyun.com/v1/customer/activities/findPage -> /pcapi/dynamic
        dynamic_findPage: 'dynamic/customer/activities/findPage', // 动态列表
        dynamic_statistics: 'dynamic/customer/activities/statistics', // 统计动态类型以及数值
        dynamic_monthStatistics: 'dynamic/customer/activities/monthStatistics', // 动态月份数据分组
        dynamic_yearStatistics: 'dynamic/customer/activities/yearStatistics', // 动态年份数据分组
        dynamic_total: 'dynamic/customer/activities/total', // 动态列表总记录数查询
        dynamic_findType: 'dynamic/customer/activities/display/findType', // 查询展示类型
        dynamic_delFollow: 'dynamic/customer/activities/delFollow', // 删除跟进
        //  启信宝
        businessContact: 'dynamic/customer/activities/businessInfo/businessContact', // 企业详情
        businessName: 'dynamic/customer/activities/businessInfo/businessName', // 模糊查询公司名称
        foreignBusinessInfo: 'dynamic/customer/activities/businessInfo/foreignBusinessInfo', // 外国企业信息
        businessInfo: 'dynamic/customer/activities/businessInfo/info', // 企业工商信息
        searchBusiness: 'dynamic/customer/activities/businessInfo/searchBusiness', // 查找中国企业数据 post
        findByRegion: 'dynamic/customer/activities/businessInfo/findByRegion', // 获取所有省份
        findByType: 'dynamic/customer/activities/businessInfo/findByType', // 获取塞选条件 1.注册资本 2.成立时间 3.企业状态 4.企业类型
        getIndustry: 'dynamic/customer/activities/businessInfo/getIndustry', // 获取所有1级行业
        insertAttention: 'dynamic/customer/activities/businessInfo/insertAttention', // 关注 post
        removeAttention: 'dynamic/customer/activities/businessInfo/removeAttention' // 取消关注 post
    }
}
