<template>
    <div>
        <ul class="FootNav">
            <li v-for="(item,index) in navData" :key="index" @touchstart.stop="$router.push(item.url)" :class="{'active':getUrl(item)===currentUrl}">
                <i class="iconfont" :class="item.icon"></i>
                <span class="text">{{item.text}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'FootNav',
    data() {
        return {
            navData: [
                {
                    url: '/workbench',
                    icon: 'icon-desk',
                    text: '工作台'
                },
                {
                    url: '/client',
                    icon: 'icon-customer',
                    text: '客户'
                },
                {
                    url: '/mail',
                    icon: 'icon-mail',
                    text: '孚盟邮'
                },
                /* {
                    url: '/find',
                    icon: 'icon-sou',
                    text: '找买家'
                }, */
                {
                    url: '/app',
                    icon: 'icon-app',
                    text: '应用'
                }
            ],
            currentUrl: ''
        }
    },
    computed: {

    },
    mounted() {
        this.getCurrentUrl()
    },
    methods: {
        getUrl(item) {
            let thisUrl = item.url.split('/')[1]
            return thisUrl
        },
        // 高亮样式
        isActive(item) {
            let thisUrl = item.url.split('/')[1]
            let routerUrl = this.$router.history.current.path.split('/')[1]
            return thisUrl === routerUrl ? 'active' : ''
        },
        getCurrentUrl() {
            this.currentUrl = this.$router.history.current.path.split('/')[1]
        }
    },
    watch: {
        $route() {
            this.getCurrentUrl()
        }
    }
}
</script>

<style lang="less" scoped>
ul.FootNav {
    .height(50px);
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #f4f4f4;
    .flex();
    > li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .iconfont {
            color: #b6bac6;
            .font-size(24px);
        }
        .text {
            color: #909399;
            user-select: none;
            .font-size(10px);
        }
        &.active {
            .iconfont,
            .text {
                color: rgba(208, 2, 27, 1);
            }
        }
    }
}
</style>
