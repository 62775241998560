export const getName = (item) => {
    let name = ''
    switch (item.category) {
        case 1:
            name = item.contName || substringName(item.address)
            break
        case 3:
            name = item.ownerName || substringName(item.address)
            break
        case 2:
        case 4:
        default:
            name = item.personal || substringName(item.address)
            break
    }
    return name
}
export const getType = type => {
    let str = ''
    const typeStrates = {
        'enquiry': '询',
        'notification': '通',
        'recruit': '招'
    }
    if (typeStrates.hasOwnProperty(type)) {
        str = typeStrates[type]
    }
    return str
}

// 名称截取
export const substringName = adderss => {
    if (adderss) {
        let str = adderss.split('@')
        return str[0]
    }
}
