import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { store } from '@/store'
import mixin from '@/mixin'
import '@/libs'
import '@/globalComponents'
import '@/directive'
import { i18n } from '@/plugin'

Vue.mixin(mixin)

let vm = new Vue({
    router,
    store,
    mixins: [mixin],
    i18n,
    render: h => h(App)
}).$mount('#app')
window.vm = vm
