import { mapGetters, mapMutations } from 'vuex'
import { getItem, getIsApp, isGongChuang } from '../libs/utils'
export default {
    data() {
        return {
            homeTitle: 'Home page'
        }
    },
    computed: {
        ...mapGetters(['individualConfigInfo', 'companie']),
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() { },
    mounted() { },
    methods: {
        ...mapMutations({
            set_headerTitle: 'SET_HEADERTITLE',
            set_formKey: 'SET_FORMKEY',
            set_routParameters: 'SET_ROUTPARAMETERS'
        }),
        setHeaderTitle(title) {
            this.set_headerTitle(title)
        },
        g_setQuery(option = {}) {
            return Object.assign(this.$route.query, option)
        },
        g_setLanguage(lang) {
            this.$moment.locale(lang)
            this.$setI18nLanguage(lang)
            document.body.classList.remove('en')
            document.body.classList.add('zh-cn')
        },
        // 全局快捷新增
        quickAdd() {
            let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585272488531')) // 新增
            setMenu.onClick(() => {
                this.openSheet()
            })
        },
        goToNewMail(params = {}) {
            // 上海孚盟 飞往 安徽省金裕印铁制罐有限公司 东莞市宇万塑胶制品有限公司 绍兴柯桥米特纺织有限公司 孚盟MX产品体验 安吉宏德医疗 深圳奥尼电子股份有限公司 东莞市建嘉实业有限公司
            // let whiteCidList = [10264, 16085, 68787, 50657, 34255, 22997, 83642, 77678, 86539]
            // let companyInfo = JSON.parse(this.Global.utils.getItem('companie'))
            // if (window.isNewVersion && whiteCidList.includes(companyInfo.cId)) {
            //     let message = JSON.stringify({ type: 'writeMail', params })
            //     window.ReactNativeWebView ? window.ReactNativeWebView.postMessage(message) : window.postMessage(message)
            // } else {
            //     if (JSON.stringify(params) === '{}') {
            //         this.$router.push('/mail/NewMail')
            //     } else {
            //         this.$router.push({ name: 'NewMail', params })
            //     }
            // }
            console.log(params)
            const BLACK_LIST = ['Z', 'D']
            if (getItem('isNewVersion') == '1') {
                if (getIsApp()) {
                    hybrid.createEmail(JSON.stringify({ ...params, ctId: `${this.currentCtid}` }))
                    return
                }
            }

            if (getItem('isNewVersion') == '0') {
                if (!BLACK_LIST.includes(params['actionType']) && getIsApp()) {
                    hybrid.createEmail(JSON.stringify({ ...params, ctId: `${this.currentCtid}` }))
                    return
                }
            }

            if (JSON.stringify(params) === '{}') {
                this.$router.push('/mail/NewMail')
            } else {
                this.$router.push({ name: 'NewMail', params })
            }
        },
        async openSheet() {
            try {
                let option = [{
                    name: this.$t('mx_mobile.Client.1585300521873'), // 新增客户
                    index: 0
                }, {
                    name: this.$t('mx_mobile.Client.1585300691680'), // 新增联系人
                    index: 1
                }, {
                    name: this.$t('mx_mobile.Client.1585300703687'), // 新增跟进
                    index: 2
                }, {
                    name: this.$t('mx_mobile.Client.1585304936162'), // 写邮件
                    index: 3
                }, {
                    name: this.$t('mx_mobile.Client.1585548055236'), // 名片扫描
                    index: 4
                }]
                const priceCalcObj = {
                    name: this.$t('mx_mobile.common.1665978240345'), // '新增算价单'
                    index: 5
                }
                if (isGongChuang(this.companie.cId)) {
                    option.push(priceCalcObj)
                }
                let res = await this.Global.utils.actionSheet.open(option)
                console.log(res)
                switch (res.index) {
                    case 0:
                        this.$router.push('/client/add/BF001')
                        break
                    case 1:
                        this.$router.push('/client/add/BF003')
                        break
                    case 2:
                        this.$router.push('/client/add/BF004')
                        break
                    case 3:
                        this.goToNewMail()
                        break
                    case 4:
                        this.$router.push('/client/businessCard')
                        // let _this = this
                        // dd.biz.util.scanCard({ // 无需传参数
                        //   onSuccess: function(data) {
                        //     let item = JSON.stringify(data)
                        //     _this.Global.utils.setItem('businessCardData', item)
                        //     setTimeout(function () {
                        //       _this.$router.push('/client/businessCard')
                        //     }, 100)
                        //   },
                        //   onFail: function(err) {
                        //     _this.$toast.fail({msg: err})
                        //   }
                        // })
                        break
                    case 5:
                        this.$router.push('/PriceCalc/add/CP010')
                        break
                    case -1: // 点取消
                        break
                    default:
                        this.$toast.fail({ msg: JSON.stringify(res) })
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 号码统一打电话
        openTel(num) {
            if (getIsApp()) {
                hybrid.callPhone(num)
                return
            }
            window.location.href = 'tel:' + num
        },
        /**
         * 全局图片URL
         * id为：文件或图片id
         * config有：
         *  200x200（截取） 或
         *  200x200s（截取） 或
         *  orig（原生大小）
         *  */
        getGlobalImgSrc(id, config) {
            if (!id || id === '') {
                return '/images/avatar/noAvatar.png' // 默认
            } else {
                let size = (!config || config === '') ? 'orig' : config
                return this.Global.config.imgBaseSrc + size + '/' + id
            }
        },
        updateFormKey(next) {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_formKey).then((res) => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (next) {
                        next(res.data.data.formKey)
                    }
                    this.set_formKey(res.data.data.formKey)
                } else {
                    if (next) {
                        next('', res.data.msg)
                    }
                    this.set_formKey('')
                }
            }, (res) => {
                if (next) {
                    next('', this.Global.config.errorTitle)
                }
                this.set_formKey('')
            })
        },
        getFormKey(next, msg) {
            if (this.formKey && this.formKey != '') {
                next(this.formKey)
            } else {
                this.updateFormKey(next)
            }
        },
        // 跳转到详情页
        clientDetailPage(item, type) {
            if (item.moduleCode && item.moduleCode != '0' && item.moduleCode != 'PS001' && item.billId != '0') {
                let { moduleCode, billId } = item
                // let viewName = 'list'
                if (item.delState == '0' && item.seasFlag == '0') {
                    viewName = 'list'
                } else if (item.delState == '1') {
                    viewName = 'recycle'
                } else if (item.seasFlag == '1') {
                    viewName = 'seas'
                }
                if (moduleCode == 'SC001' || moduleCode == 'SC002') { } else if (moduleCode == 'SC003') { } else {
                    this.$router.push('/client/detail/' + billId)
                }
                // this.$MXEventBus.emit('listaddTab', Object.assign(item))
            }
        },
        // 消息跳转
        msgJump(item) {
            if (!item.msgSubType) { return }
            switch (item.msgSubType) {
                case 1:
                case 2:
                case 3:
                case 8:
                case 15:
                    switch (item.moduleCode) {
                        case 'EM001':
                            break
                        case 'BF003':
                            //  this.$router.push('/main/client/tabs/list/' + item.moduleCode)
                            break
                        default:
                            this.clientDetailPage(item, item.msgSubType)
                    }
                    break
                case 24:
                    // 日志提醒提交, 跳转写日志
                    if (this.$route.path.indexOf('/salelog/writeLog') == -1) {
                        this.$router.push('/salelog/writeLog')
                    }
                    break
                case 25:
                    // 日志提交提醒接收人，通过参数跳转到日志报表 billId, billJson
                    if (this.$route.path.indexOf('/salelog/statisticsLog') == -1) {
                        // this.$router.push('/main/client/log')
                    }
                    // 隐式传递参数，在路由表里给映射的路径起一个别名，通过name来取别名
                    // billId: 1, billJson: "2019-10-18"
                    this.$router.push({ 'name': 'StatisticsLog', params: { billId: item.billId, billJson: item.billJson } })
                    break
                default:
                    console.log(item.msgSubType)
            }
        },
        toClient_g(obj) { // 跳转客户列表
            let { modelCode, interval, argument } = obj
            let timeType = 'createDate'
            if (obj.timeType === '2') {
                timeType = 'willInSeaDate'
            } else if (obj.timeType === '3') {
                timeType = 'lastTrackDate'
            };
            let data = argument
            let date = new Date()
            interval = interval + ''
            if (interval === '1') { // 本日
                data[timeType + '_gt'] = this.$m_unifiedTime(date.toLocaleDateString())
                data[timeType + '_lt'] = this.$m_unifiedTime(date.toLocaleDateString()).replace('00:00:00', '23:59:59')
            }
            if (interval === '2') { // 本周
                data[timeType + '_gt'] = this.$m_unifiedTime(getWeekStartDate(date))
                data[timeType + '_lt'] = this.$m_unifiedTime(getWeekEndDate(date)).replace('00:00:00', '23:59:59')
            }

            if (interval === '3') { // 本月
                data[timeType + '_gt'] = this.$m_unifiedTime(date.toLocaleDateString()).slice(0, 8) + '01 00:00:00'
                data[timeType + '_lt'] = this.$m_unifiedTime(date.toLocaleDateString()).slice(0, 8) + mGetDate(date.getFullYear(), date.getMonth() + 1) + ' 23:59:59'
            }
            if (interval === '4') { // 本年
                data[timeType + '_gt'] = date.getFullYear() + '-01-01 00:00:00'
                data[timeType + '_lt'] = date.getFullYear() + '-12-' + mGetDate(date.getFullYear(), 12) + ' 23:59:59'
            }
            if (interval === '5') { // 去年
                data[timeType + '_gt'] = parseInt(date.getFullYear()) - 1 + '-01-01 00:00:00'
                data[timeType + '_lt'] = parseInt(date.getFullYear()) - 1 + '-12-' + mGetDate(date.getFullYear() - 1, 12) + ' 23:59:59'
            }
            if (interval === '6') { // 最近6月
                let date1 = new Date()
                date1.setMonth(date.getMonth() - 6)
                data[timeType + '_gt'] = this.$m_unifiedTime(date1)
                data[timeType + '_lt'] = this.$m_unifiedTime(date)
            }
            if (interval === '7') { // 最近12个月
                let date2 = new Date()
                date2.setMonth(date.getMonth() - 12)
                data[timeType + '_gt'] = this.$m_unifiedTime(date2)
                data[timeType + '_lt'] = this.$m_unifiedTime(date)
            }
            if (interval === '8') { // 全部

            }
            if (interval === 'a1') { // 7天内
                data[timeType + '_gt'] = this.$m_unifiedTime(date)
                data[timeType + '_lt'] = this.$m_unifiedTime(funDate(7))
            }
            if (interval === 'a2') { // 15天前
                data[timeType + '_lt'] = this.$m_unifiedTime(funDate(-15))
            }
            if (interval === 'a3') { // 30天前
                data[timeType + '_lt'] = this.$m_unifiedTime(funDate(-30))
            }
            this.set_routParameters(data)
            if (modelCode == 'PS001') {
                this.$router.push('/main/am/Inquiries')
            } else if (modelCode == 'seas') {
                this.$router.push('/client/seas')
            } else {
                this.$router.push('/client/home')
            }

            // 获得本周的开始日期
            function getWeekStartDate(now) {
                let nowMonth = now.getMonth() // 当前月
                let nowYear = now.getFullYear() // 当前年
                let nowDayOfWeek = now.getDay() // 今天本周的第几天
                let nowDay = now.getDate() // 当前日
                let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1)
                return weekStartDate
            };
            // 获得本周的结束日期
            function getWeekEndDate(now) {
                let nowMonth = now.getMonth() // 当前月
                let nowYear = now.getFullYear() // 当前年
                let nowDayOfWeek = now.getDay() // 今天本周的第几天
                let nowDay = now.getDate() // 当前日
                let weekEndDate = new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek))
                return weekEndDate
            };

            function mGetDate(year, month) {
                var d = new Date(year, month, 0)
                return d.getDate()
            };

            function funDate(aa) { // 获取多少天前后的日期
                let date1 = new Date()
                let date2 = new Date(date1)
                date2.setDate(date1.getDate() + aa)
                return date2
            };
        },
        converSize(limit) {
            // 传入字节单位
            var size = ''
            if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
                size = limit.toFixed(2) + 'B'
            } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
                size = (limit / 1024).toFixed(2) + 'KB'
            } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
                size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
            } else { // 其他转化成GB
                size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
            }

            var sizestr = size + ''
            var len = sizestr.indexOf('\.')
            var dec = sizestr.substr(len + 1, 2)
            if (dec == '00') { // 当小数点后为00时 去掉小数部分
                return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
            }
            return sizestr
        },
        /**
         * 判断字段是否禁用
         * @param {String} optCode 单据操作状态
         * @param {String} editState 字段的editState属性
         * @returns {Boolean} 是否禁用
         */
        fieldIsEditable(optCode, editState) {
            let disabled = false
            if (optCode == 'otEdit') {
                if (editState == '0' || editState == '3' || editState == '4') {
                    disabled = true
                }
            } else {
                if (editState == '0' || editState == '2' || editState == '4') {
                    disabled = true
                }
            }
            return disabled
        }
    }
}
