<template>
    <div v-if="headerShow" class="PublicControls">
        <Header />
    </div>
</template>
<script>
import Header from './Header/index'
import { getItem, getIsApp } from '@/libs/utils'

/**
 * 钉钉无法el.click()
 * 此组件无效
 */
export default {
    name: 'PublicControls',
    data() {
        return {
            headerShow: true
        }
    },
    components: {
        'Header': Header
    },
    watch: {
        $route(to, from) {
            if (getIsApp() && getItem('isNewVersion') == 1) {
                if (to.path == '/workbench') {
                    this.headerShow = false
                } else {
                    this.headerShow = true
                }
            }
        }
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
.PublicControls {
    z-index: 9000;
}
</style>
