<template>
    <div class="SelectCountry" v-if="isShow">
        <div :class="{'Header': true, 'app-header': getIsApp()}">
            <span class="back" @click="close()">
                <i class="iconfont icon-page-left"></i>
            </span>
            <!-- 选择草丝 -->
            {{$t('mx_mobile.common.1665984379808')}}
        </div>
        <!-- <div > -->
        <div class="list1">
            <!-- 搜索 -->
            <search-bar v-model="value" @keyup.enter.native="toSearch" :placeholder="$t('mx_mobile.Doc.1585204965787')" @btnClick="toSearch"></search-bar>
        </div>
        <div class="contenClass" ref="list">
            <van-radio-group v-model="radio">
                <ul class="list">
                    <li v-for="(item,index) in listOption" :key="index">
                        <span>{{item.text}}</span>
                        <div class="chekedClass" @click.stop>
                            <van-radio :name="item.value"></van-radio>
                        </div>
                    </li>
                </ul>
            </van-radio-group>
        </div>
        <div class="footClass" @click="submit()">
            <!-- 完成 -->
            {{$t('mx_mobile.Client.1585286721404')}}
        </div>
    </div>
</template>

<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import selectGrassSilkEvent from './event'
import { getIsApp } from '@/libs/utils'
import BScroll from 'better-scroll'
export default {
    name: 'SelectGrassSilk',
    data() {
        return {
            isShow: false,
            optionsList: [],
            listOption: [],
            value: '',
            radio: '',
            page: {
                from: 0,
                size: 100
            },
            factory: '',
            // updateFlag为true时说明草丝列表还未全部查出，false时说明草丝列表已全部查出，不用再查接口
            updateFlag: true
        }
    },
    created() {
        selectGrassSilkEvent.onChange(({ show, defaultValue, options, factory }) => {
            this.value = ''
            this.page.from = 0
            this.isShow = show
            this.factory = factory
            this.optionsList = options
            this.listOption = this.optionsList
            if (defaultValue && defaultValue.value) {
                this.radio = defaultValue.value
            }
            this.$nextTick(() => {
                this.initScroll()
            })
        })
    },
    destroyed() {
        this.$refs.scroll && this.$refs.scroll.destroy()
    },
    methods: {
        async getOptions(config = {}) {
            let data = {
                from: this.page.from,
                size: this.page.size,
                keywords: this.value,
                factory: this.factory
            }
            let url = this.Global.config.apiBaseURL + this.Global.api.v2.thirdparty_grassSilkPrice
            let options = []
            try {
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    options = res.data.data.list || {}
                    this.updateFlag = res.data.data.totalNum > this.page.from
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                console.log(err)
            }
            return options
        },
        initScroll() {
            if (!this.$refs.list) {
                return
            }
            this.scroll = new BScroll(this.$refs.list, {
                scrollY: true,
                click: true
            })
            let _this = this
            this.scroll.on('scrollEnd', () => {
                if ((this.scroll.y <= (this.scroll.maxScrollY + 50)) && _this.updateFlag) {
                    this.page.from += this.page.size
                    this.getOptions().then((options) => {
                        let optionsTemp = options.map(el => {
                            const labelStr =
                                el.grasspriceId +
                                '+-*/&' +
                                (el.grassSeries ? el.grassSeries : '') +
                                (el.dtexCode ? '_' + el.dtexCode : '') +
                                (el.grassColor ? '_' + el.grassColor : '') +
                                (el.grassLevel ? '_' + el.grassLevel : '') +
                                (el.curvature ? '_' + el.curvature : '') +
                                (el.shares ? '_' + el.shares : '')
                            return {
                                text: labelStr.split('+-*/&')[1],
                                value: labelStr
                            }
                        })
                        this.listOption = Object.freeze([...this.listOption, ...optionsTemp])
                    })
                }
            })
        },
        getIsApp,
        close() {
            selectGrassSilkEvent.close()
        },
        async toSearch() {
            this.page.from = 0
            let options = await this.getOptions()
            let optionsTemp = options.map(el => {
                const labelStr =
                    el.grasspriceId +
                    '+-*/&' +
                    (el.grassSeries ? el.grassSeries : '') +
                    (el.dtexCode ? '_' + el.dtexCode : '') +
                    (el.grassColor ? '_' + el.grassColor : '') +
                    (el.grassLevel ? '_' + el.grassLevel : '') +
                    (el.curvature ? '_' + el.curvature : '') +
                    (el.shares ? '_' + el.shares : '')
                return {
                    text: labelStr.split('+-*/&')[1],
                    value: labelStr
                }
            })
            this.listOption = Object.freeze([...optionsTemp])
        },
        submit() {
            let item = {
                text: '',
                value: ''
            }
            if (this.radio) {
                let list = this.listOption.filter(
                    (ele) => ele.value == this.radio
                )
                item = list[0]
            }
            selectGrassSilkEvent.select(item)
            // this.$emit('closeSelect', item)
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
@import "./index.less";

.zh-cn .SelectCountry .Header.app-header,
.zh-tw .SelectCountry .Header.app-header {
    background: white;
    color: black;
}
</style>
