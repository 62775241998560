import Vue from 'vue'
import api from './api'
import * as config from './config'
import * as utils from './utils'
// import * as ding from './ding'

Vue.prototype.Global = {
    api,
    config,
    utils
    //,  ding
}
