import Vue from 'vue'
import Router from 'vue-router'
import { getToken, getItem, setItem, getQueryString, getIsApp } from '@/libs/utils'
import { accessToken } from '@/libs/config'
import hybrid from '@/libs/hybrid'
import { store } from '@/store'

Vue.use(Router)

/**
 * 检测是否在app中运行并将其放入sessionStorage和 Vuex Store中
 */
const getClientStatus = () => {
    if (!getIsApp()) {
        setItem('isApp', getQueryString('isApp') === '1')
        setItem('lang', getQueryString('lang'))
    }
}

getClientStatus()

const getTabBarStatus = matched => {
    const { length } = matched
    const meta = length ? matched[length - 1].meta : false
    return meta ? Boolean(meta.hasTabBar) : false
}

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/login',
            component: () => import('@/page/Login'),
            name: 'login',
            meta: { noKeepAlive: true, isAppRoot: true }
        },
        {
            path: '/workbench',
            name: 'Workbench',
            redirect: '/workbench',
            component: () => import('@/page/WorkBench'),
            meta: { hasTabBar: true, isAppRoot: true },
            children: [
                {
                    path: '/',
                    name: 'workbenchHome',
                    component: () => import('@/page/WorkBench/Home'),
                    meta: { hasTabBar: true, isAppRoot: true }
                },
                {
                    path: 'schedule',
                    name: 'schedule',
                    component: () => import('@/page/WorkBench/Schedule'),
                    meta: { hasTabBar: false, isAppRoot: false }
                },
                {
                    path: 'subordinate',
                    name: 'subordinate',
                    component: () => import('@/page/WorkBench/Subordinate'),
                    meta: { hasTabBar: false, isAppRoot: false },
                    children: [
                        {
                            path: ':id?',
                            name: 'subordList',
                            component: () => import('@/page/WorkBench/Subordinate/SubordList'),
                            meta: { hasTabBar: false, isAppRoot: false }
                        }
                    ]
                }
            ]
        },
        {
            path: '/client',
            component: () => import('@/page/Client'),
            name: 'Client',
            meta: { hasTabBar: true, isAppRoot: true },
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: () => import('@/page/Client/Home'),
                    meta: { hasTabBar: true, isAppRoot: true }
                },
                {
                    path: 'queryRepeat',
                    name: 'queryRepeat',
                    component: () => import('@/page/Client/QueryRepeat'),
                    meta: { hasTabBar: true }
                },
                {
                    path: 'seas',
                    name: 'seas',
                    component: () => import('@/page/Client/Seas'),
                    meta: { hasTabBar: true }
                },
                {
                    path: 'focus',
                    name: 'focus',
                    component: () => import('@/page/Client/Focus'),
                    meta: { hasTabBar: true }
                },
                {
                    path: 'detail/:id?',
                    name: 'detail',
                    component: () => import('@/page/Client/Detail')
                },
                {
                    path: 'seas_detail/:id?',
                    name: 'seas_detail',
                    component: () => import('@/page/Client/Seas_detail')
                },
                {
                    path: 'add/:moduleCode?',
                    name: 'add',
                    component: () => import('@/page/Client/Add')
                },
                {
                    path: 'edit/:moduleCode?/:id?',
                    name: 'edit',
                    component: () => import('@/page/Client/Edit')
                },
                {
                    path: 'businessCard',
                    name: 'businessCard',
                    component: () => import('@/page/Client/BusinessCard'),
                    meta: {
                        noKeepAlive: true
                    } // true为不缓存
                },
                /* {
                    path: 'cropImg',
                    name: 'cropImg',
                    component: () => import('@/page/Client/CropImg'),
                    meta: {
                        noKeepAlive: true
                    }
                }, */
                {
                    path: 'nearbyConst/:id?',
                    name: 'NearbyConst',
                    component: () => import('@/page/Client/NearbyConst'),
                    meta: {
                        noKeepAlive: true
                    } // true为不缓存
                },
                {
                    path: 'strange',
                    name: 'Strange',
                    component: () => import('@/page/Client/Strange'),
                    meta: {
                        noKeepAlive: true
                    }
                },
                {
                    path: 'ownerSelector',
                    name: 'OwnerSelector',
                    component: () => import('@/page/Client/CustPublic/OwnerSelector')
                }
            ]
        },
        {
            path: '/priceCalc',
            component: () => import('@/page/PriceCalc'),
            name: 'PriceCalc',
            meta: { hasTabBar: true, isAppRoot: true },
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: () => import('@/page/PriceCalc/home'),
                    meta: { hasTabBar: true, isAppRoot: true }
                },
                {
                    path: 'detail/:id?',
                    name: 'detail',
                    component: () => import('@/page/PriceCalc/detail')
                },
                {
                    path: 'add/:moduleCode?',
                    name: 'add',
                    component: () => import('@/page/PriceCalc/add')
                },
                {
                    path: 'edit/:moduleCode?/:id?',
                    name: 'edit',
                    component: () => import('@/page/PriceCalc/edit')
                }
            ]
        },
        {
            path: '/mail',
            component: () => import('@/page/Mail'),
            name: 'Mail',
            // redirect: '/mail/home',
            meta: { hasTabBar: true, isAppRoot: true },
            children: [
                {
                    path: 'home',
                    name: 'MailHome',
                    meta: { hasTabBar: true, isAppRoot: true },
                    component: () => import('@/page/Mail/Home')
                },
                {
                    path: 'folderSelection',
                    name: 'folderSelection',
                    component: () => import(
                        '@/page/Mail/MailPublic/FolderAction/FolderSelection'
                    )
                },
                {
                    path: 'folderAdd',
                    name: 'folderAdd',
                    component: () => import('@/page/Mail/MailPublic/FolderAction/FolderAdd')
                },
                {
                    path: 'mailAccount',
                    name: 'mailAccount',
                    component: () => import('@/page/Mail/MailPublic/MailAccount')
                },
                {
                    path: 'mailList',
                    name: 'mailList',
                    // meta: { hasTabBar: true },
                    component: () => import('@/page/Mail/Home/MaliList')
                },
                {
                    path: 'DetailRight',
                    name: 'DetailRight',
                    component: () => import('@/page/Mail/Home/DetailRight')
                },
                {
                    path: 'TagList',
                    name: 'TagList',
                    component: () => import('@/page/Mail/MailPublic/TagAction/TagList')
                },
                {
                    path: 'TagAdd',
                    name: 'TagAdd',
                    component: () => import('@/page/Mail/MailPublic/TagAction/TagAdd')
                },
                {
                    path: 'SubjectUpdate',
                    name: 'SubjectUpdate',
                    component: () => import('@/page/Mail/MailPublic/SubjectUpdate')
                },
                {
                    path: 'DialogInDistribute',
                    name: 'DialogInDistribute',
                    component: () => import('@/page/Mail/MailPublic/DialogInDistribute')
                },
                {
                    path: 'PersonnelSelection',
                    name: 'PersonnelSelection',
                    component: () => import('@/page/Mail/MailPublic/PersonnelSelection')
                },
                {
                    path: 'NewMail',
                    name: 'NewMail',
                    component: () => import('@/page/Mail/NewMail')
                },
                {
                    path: 'FullScreenDisplay',
                    name: 'FullScreenDisplay',
                    component: () => import('@/page/Mail/MailPublic/FullScreenDisplay')
                },
                {
                    path: 'ReportSpam',
                    name: 'ReportSpam',
                    component: () => import('@/page/Mail/MailPublic/ReportSpam')
                },
                {
                    path: 'MovingBox',
                    name: 'MovingBox',
                    component: () => import('@/page/Mail/MailPublic/FileMove/MovingBox')
                },
                {
                    path: 'SettingUpReminder',
                    name: 'SettingUpReminder',
                    component: () => import('@/page/Mail/MailPublic/SettingUpReminder')
                },
                {
                    path: 'selectCustomer',
                    name: 'selectCustomer',
                    component: () => import('@/page/Mail/NewMail/selectCustomer')
                },
                {
                    path: 'selectContacts/:id?',
                    name: 'selectContacts',
                    component: () => import('@/page/Mail/NewMail/selectContacts')
                }
            ]
        },
        {
            path: '/app',
            name: 'App',
            component: () => import('@/page/App'),
            meta: { hasTabBar: true, isAppRoot: true }
        },
        {
            path: '/find',
            name: 'Find',
            component: () => import('@/page/Find'),
            meta: { hasTabBar: true, isAppRoot: true }
        },
        {
            path: '/my',
            name: 'My',
            component: () => import('@/page/My'),
            children: [
                {
                    path: 'center',
                    name: 'Center',
                    component: () => import('@/page/My/Center'),
                    meta: {
                        hasTabBar: true,
                        noKeepAlive: true
                    }
                },
                {
                    path: 'setting',
                    name: 'Setting',
                    component: () => import('@/page/My/Setting'),
                    meta: {
                        hasTabBar: true
                    }
                },
                {
                    path: 'order',
                    name: 'Order',
                    component: () => import('@/page/My/Order'),
                    meta: {
                        hasTabBar: false,
                        noKeepAlive: true
                    }
                },
                {
                    path: 'orderDetail/:id?',
                    name: 'OrderDetail',
                    component: () => import('@/page/My/OrderDetail'),
                    meta: {
                        hasTabBar: false,
                        noKeepAlive: true
                    }
                },
                {
                    path: 'payment',
                    name: 'Payment',
                    component: () => import('@/page/My/Payment'),
                    meta: {
                        hasTabBar: false,
                        noKeepAlive: true
                    }
                }
            ]
        },
        {
            path: '/document',
            name: 'Document',
            component: () => import('@/page/Document'),
            children: [
                {
                    path: 'search',
                    component: () => import('@/page/Document/Search')
                },
                {
                    path: 'mydocument',
                    name: 'MyDocument',
                    component: () => import('@/page/Document/MyDocument')
                },
                {
                    path: 'knowledgebase',
                    name: 'KnowledgeBase',
                    component: () => import('@/page/Document/KnowledgeBase')
                },
                {
                    path: 'deletefile',
                    name: 'DeleteFile',
                    component: () => import('@/page/Document/DeleteFile')
                },
                {
                    path: 'movefile',
                    name: 'MoveFile',
                    component: () => import('@/page/Document/MoveFile')
                },
                {
                    path: 'powerlist',
                    name: 'PowerList',
                    component: () => import('@/page/Document/PowerList')
                },
                {
                    path: 'powersetting',
                    name: 'PowerSetting',
                    component: () => import('@/page/Document/PowerSetting')
                }
            ]
        },
        {
            path: '/salelog',
            component: () => import('@/page/SaleLog'),
            children: [
                {
                    path: 'writeLog',
                    name: 'WriteLog',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/SaleLog/WriteLog')
                },
                {
                    path: 'viewLog',
                    name: 'ViewLog',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/SaleLog/ViewLog')
                },
                {
                    path: 'statisticsLog',
                    name: 'StatisticsLog',
                    component: () => import('@/page/SaleLog/StatisticsLog')
                },
                {
                    path: 'index',
                    name: 'Index',
                    meta: { hasTabBar: false },
                    // query: true,
                    component: () => import('@/page/SaleLog/Vue/TargetDetail')
                }
            ]
        },
        {
            path: '/goods',
            component: () => import('@/page/Goods'),
            children: [
                {
                    path: 'goodsList',
                    name: 'GoodsList',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/Goods/GoodsList')
                }
            ]
        },
        {
            path: '/sale',
            component: () => import('@/page/Sale'),
            children: [
                {
                    path: 'tabs/list/:moduleCode?',
                    name: 'SaleTabs',
                    component: () => import('@/page/Sale/NewView/index.vue'),
                    meta: { noKeepAlive: true, hasTabBar: false }
                },
                {
                    path: 'tabs/new/:moduleCode?/:id?',
                    name: 'SaleNewEdit',
                    component: () => import('@/page/Sale/NewAndEditVue/index.vue'),
                    meta: { noKeepAlive: true, hasTabBar: false }
                },
                {
                    path: 'tabs/list/:moduleCode?/:id?',
                    name: 'SaleDetail',
                    component: () => import('@/page/Sale/Detail/index.vue'),
                    meta: { noKeepAlive: true, hasTabBar: false }
                }
            ]
        },
        {
            path: '/mailmonitor',
            component: () => import('@/page/MailMonitor'),
            children: [
                {
                    path: 'screen',
                    name: 'Screen',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/Screen')
                },
                {
                    path: 'realanomaly',
                    name: 'RealAnomaly',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/RealAnomaly')
                },
                {
                    path: 'monitordetail',
                    name: 'MonitorDetail',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/MonitorDetail')
                },
                {
                    path: 'search',
                    name: 'Search',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/Search')
                },
                {
                    path: 'alltask',
                    name: 'AllTask',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/AllTask')
                },
                {
                    path: 'tasklog',
                    name: 'TaskLog',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/TaskLog')
                },
                {
                    path: 'taskmonitor',
                    name: 'TaskMonitor',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/TaskMonitor')
                },
                {
                    path: 'recentlyaccept',
                    name: 'RecentlyAccept',
                    meta: { noKeepAlive: true, hasTabBar: false },
                    component: () => import('@/page/MailMonitor/RecentlyAccept')
                }
            ]
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    let token = getToken()[accessToken]
    const isApp = getIsApp()
    if (token) {
        if (to.path === '/login' || to.path === '/') {
            if (isApp) {
                try {
                    const hasToken = await getItemFromMxNative()
                    await dataInit()
                    if (hasToken) {
                        hybrid.commitMessage('webMounted')
                        hybrid.checkVersion()
                        next(false)
                        return
                    } else {
                        hybrid.commitMessage('tokenExpired')
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            next({
                name: 'Workbench',
                replace: true
            })
        } else {
            if (isApp) {
                await hybrid.checkVersion()
            }
            next()
        }
    } else {
        if (to.path === '/login') {
            if (isApp) {
                try {
                    console.log('开始写入')
                    const hasToken = await getItemFromMxNative()
                    await dataInit()
                    if (hasToken) {
                        hybrid.commitMessage('webMounted')
                        hybrid.checkVersion()
                        next(false)
                        return
                    } else {
                        hybrid.commitMessage('tokenExpired')
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            next()
        } else {
            if (isApp) {
                const hasToken = await getItemFromMxNative()
                if (JSON.stringify(hasToken) == '{}') {
                    hybrid.logout()
                } else {
                    await dataInit()
                    hybrid.commitMessage('webMounted')
                    hybrid.checkVersion()
                }
                return
            }
            next({
                path: '/login',
                replace: true
            })
        }
    }
})

router.afterEach((to, from) => {
    const hasTabBar = getTabBarStatus(to.matched)
    const navBarName = to.matched[0] ? to.matched[0].name : null
    const isApp = getIsApp()
    if (isApp) {
        hybrid.commitMessage('changeNavBar', navBarName)
        hybrid.commitMessage('hasTabBar', hasTabBar)
    }
})

/**
 * 将App.vue中的方法移植过来，进行数据初始化
 */
async function dataInit() {
    const companie = getItem('companie')
    const userInfo = getItem('userInfo')
    if (companie) {
        store.commit('SET_COMPANIE', JSON.parse(companie))
        let companieObj = JSON.parse(companie || '{}')
        await store.dispatch('setGreyFunctions', { cId: companieObj.cId })
    }
    if (userInfo) {
        store.commit('SET_USERINFO', JSON.parse(userInfo))
    }

    await store.dispatch('setContactList')
    console.log('存入成功！')
}

/**
 * 从App中拿到登录信息
 */
async function getItemFromMxNative() {
    let loginData
    const isIos = /(iP)/g.test(navigator.userAgent)
    if (isIos) {
        loginData = await hybrid.sendMessage('getLoginInfo')
    } else {
        loginData = JSON.parse(window.MxNative.getLoginInfo())
    }
    setItemFromMxNative(loginData)
    return !!loginData
}

/**
 * 将登录信息对象存入sessionStorage
 * @param {Object} data 登录信息对象
 */
function setItemFromMxNative(data) {
    try {
        const { companies: company, accessToken } = data
        setItem('companie', JSON.stringify(company)) // 公司信息保存在本地
        setItem('accessToken', company.accessToken.value)
        setItem('individualAccessToken', accessToken.value)
    } catch (error) {
        console.log('返回登录信息错误！', error)
    }
}

/** 绑定routerChange事件 */
hybrid.on('routerChange', async name => {
    await router.replace({ name: name, query: { isApp: 1 } })
})

/** 绑定routerPush事件 */
hybrid.on('routerPush', async (name, query) => {
    await router.push({ name: name, query: query || {} })
})

export default router
