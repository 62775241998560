const hostConfig = {
    'dev': {
        serverHost: 'http://localhost:9010',
        imgBaseSrc: 'https://sf.laifuyun.com/img/',
        uploadUrl: 'https://aapi.laifuyun.com/v2/uploadTest',
        downloadBaseUrl: 'https://aapi.laifuyun.com/v2/download/',
        previewUrl: 'https://aapi.laifuyun.com/v2/preView/',
        ossFileUrl: 'https://files.laifuyun.com/'
    },
    'test': {
        serverHost: 'https://orange.laifuyun.com',
        imgBaseSrc: 'https://sf.laifuyun.com/img/',
        uploadUrl: 'https://aapi.laifuyun.com/v2/uploadTest',
        downloadBaseUrl: 'https://aapi.laifuyun.com/v2/download/',
        previewUrl: 'https://aapi.laifuyun.com/v2/preView/',
        ossFileUrl: 'https://files.laifuyun.com/'
    },
    'sim': {
        serverHost: 'https://orange.laifuyun.com',
        imgBaseSrc: 'https://sf.laifuyun.com/img/',
        uploadUrl: 'https://aapi.laifuyun.com/v2/uploadTest',
        downloadBaseUrl: 'https://aapi.laifuyun.com/v2/download/',
        previewUrl: 'https://aapi.laifuyun.com/v2/preView/',
        ossFileUrl: 'https://files.laifuyun.com/'
    },
    'prod': {
        serverHost: 'https://mxh5api.easyland-group.com',
        imgBaseSrc: 'https://mxfs.easyland-group.com/img/',
        uploadUrl: 'https://mxup.easyland-group.com/v2/uploadTest',
        downloadBaseUrl: 'https://mxup.easyland-group.com/v2/download/',
        previewUrl: 'https://mxup.easyland-group.com/v2/preView/',
        ossFileUrl: 'https://file.fumamx.com/'
    }
}
export const STAGE_ENV = process.env.VUE_APP_STAGE_ENV

const host = hostConfig[STAGE_ENV]

if (STAGE_ENV === 'dev') {
    const devServerHost = process.env.VUE_APP_DEV_SERVER_HOST
    if (devServerHost && devServerHost !== '') {
        Object.assign(host, { serverHost: devServerHost })
    }
}
// api 接口地址
export const serverHost = host.serverHost
// api接口根路径
export const apiBaseURL = host.serverHost + '/h5api/'

// 图片展示接口根路径
export const imgBaseSrc = host.imgBaseSrc

// 上传文件到文件服务器接口
export const uploadUrl = host.uploadUrl

// oss文件服务器接口
export const ossFileUrl = host.ossFileUrl

// 文件下载
export const downloadBaseUrl = host.downloadBaseUrl

// 文件预览
export const previewUrl = host.previewUrl

// 接口返回成功，并且数据有效状态
export const RES_OK = '0'

// 企业Token key
export const accessToken = 'accessToken'

// 个人Token key
export const individualAccessToken = 'individualAccessToken'

export const errorTitle = '网络异常'
