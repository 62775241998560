import { getUUID, clearItem, setItem } from '@/libs/utils'
import { getName, substringName } from '@/page/Mail/PublicMethod'

const hybrid = {
    data: {},
    callback: {},
    listeners: {},
    isIos: /(iP)/g.test(navigator.userAgent),
    NEW_VERSION_IOS: 214,
    NEW_VERSION_ANDROID: 20211,
    /**
     * iOS WkWebview 的原生通信方法
     *
     * @param {*} message 传给iOS的post message中的消息
     */
    mxInteraction(message = null) {
        window.webkit.messageHandlers.MxNative.postMessage(message)
    },
    /**
     * 原生App应用通过事件名称调用web应用事件
     *
     * @param {String} name 事件名称
     * @param  {...any} args 传入的参数
     */
    nativeCallByName: function (name, ...args) {
        const methods = [...this.listeners[name]] || []
        if (methods.length > 0) {
            methods.map(method => method(...args))
        }
    },
    /**
     * 原生App应用通过事件ID调用web应用事件
     *
     * @param {String} name 事件ID
     * @param  {*} arg 传入的参数
     */
    nativeCallById: function (id, arg) {
        const cb = this.callback[id]
        if (cb) {
            cb(arg)
            delete this.callback[id]
        }
    },
    // ios原生调用H5
    tokenExpiredResetByNative: function (arg) {
        const tokenReset = JSON.parse(arg)
        const { companies: company, accessToken } = tokenReset
        setItem('companie', JSON.stringify(company))
        setItem('accessToken', company.accessToken.value)
        setItem('individualAccessToken', accessToken.value)
    },
    // ios原生调用H5
    appOpenCustomer: function (arg) {
        const showDetail = JSON.parse(arg)
        if (!showDetail.category) {
            let name = getName(showDetail)
            // TODO:跳转到新建客户页
            window.vm.$router.push({ name: 'Strange', params: { name: substringName(name), mailAddress: showDetail.address } })
            return
        }
        if (showDetail.category.toString() === '1') {
            // TODO:跳转到客户页
            window.vm.$router.push({ name: 'detail', params: { id: showDetail.custId } })
        }
    },
    /**
     * 添加回调函数的通信方法
     * @param {String} nativeMethod App方法的名称
     * @param {Function} cb 回调函数
     */
    sendMessage(nativeMethod, cb) {
        if (!cb) {
            return new Promise(resolve => {
                this.sendMessage(nativeMethod, resolve)
            })
        }
        const callbackId = getUUID()
        this.callback[callbackId] = cb
        // 调用 原生方法
        this.mxInteraction({ action: nativeMethod, callbackId: callbackId })
    },
    /**
     * On注册事件的统一方法
     * @param {String} event 事件名称
     * @param {Function} callback 回调函数
     */
    on: function (event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = []
        }
        this.listeners[event].push(callback)
    },
    /**
     * 暴露出去统一的接口来调用后续封装的方法
     * @param {String} method 方法名
     * @param  {...any} args 参数
     */
    commitMessage(method, ...args) {
        this[method.toString()](...args)
    },
    /**
     * 登出
     */
    logout() {
        clearItem()
        if (this.isIos) {
            this.mxInteraction({ action: 'Loginout' })
        } else {
            window.MxNative.Loginout()
        }
    },
    // 返回到原生
    goNative() {
        if (this.isIos) {
            this.mxInteraction({ action: 'mx_popVC' })
        } else {
            window.MxNative.mx_popVC()
        }
    },
    /**
     * H5页面跳转至原生客户跟进页
     */
    customerFollow() {
        if (this.isIos) {
            this.mxInteraction({ action: 'customerFollow' })
        } else {
            window.MxNative.customerFollow()
        }
    },
    /**
     * H5页面跳转至原生关于MX页
     */
    aboutMX() {
        if (this.isIos) {
            this.mxInteraction({ action: 'aboutMX' })
        } else {
            window.MxNative.aboutMX()
        }
    },
    /**
     * H5页面跳转至原生写日志
     */
    toWriteLog() {
        if (this.isIos) {
            this.mxInteraction({ action: 'toWriteLog' })
        } else {
            window.MxNative.toWriteLog()
        }
    },
    /**
     * H5页面跳转至原生看日志
     */
    toLookLog() {
        if (this.isIos) {
            this.mxInteraction({ action: 'toLookLog' })
        } else {
            window.MxNative.toLookLog()
        }
    },
    /**
     * H5页面跳转至原生报价单
     */
    goOfferingList() {
        if (this.isIos) {
            this.mxInteraction({ action: 'goOfferingList' })
        } else {
            window.MxNative.goOfferingList()
        }
    },
    /**
     * H5页面跳转至原生采购合同
     */
    goPurchaseContract() {
        if (this.isIos) {
            this.mxInteraction({ action: 'goPurchaseContract' })
        } else {
            window.MxNative.goPurchaseContract()
        }
    },
    /**
     * H5页面跳转至原生销售订单
     */
    goSaleList() {
        if (this.isIos) {
            this.mxInteraction({ action: 'goSaleList' })
        } else {
            window.MxNative.goSaleList()
        }
    },
    /**
     * 变更导航栏，并更新路由指向
     * @param {String} navBarName 导航栏名称
     */
    changeNavBar(navBarName) {
        if (this.isIos) {
            this.mxInteraction({
                action: 'changeNavBar',
                navBarName: navBarName
            })
        } else {
            window.MxNative.changeTabalBar(navBarName)
        }
    },
    /**
     * 告诉App当前页面是否显示导航栏
     * @param {Boolean}} hasTabBar 是否有导航栏
     */
    hasTabBar(hasTabBar) {
        if (this.isIos) {
            this.mxInteraction({ action: 'hasTabBar', hasTabBar })
        } else {
            window.MxNative.hasTabBar(hasTabBar)
        }
    },
    /**
     * 告诉App页面挂载成功
     */
    webMounted() {
        if (this.isIos) {
            this.mxInteraction({ action: 'webMounted' })
        } else {
            window.MxNative.webMounted()
        }
    },
    /**
     * 告诉App当前用户的Token已失效
     */
    tokenExpired() {
        if (this.isIos) {
            this.mxInteraction({ action: 'tokenExpired' })
        } else {
            window.MxNative.tokenExpired()
        }
    },
    /**
     * 传给App电话号码
     * @param {Number} number 电话号码
     */
    callPhone(number) {
        if (this.isIos) {
            this.mxInteraction({ action: 'callPhone', value: number })
        } else {
            window.MxNative.callPhone(number)
        }
    },
    /**
     * 告诉App打开新的webview页面并传入相应数据
     * @param {Object} data 数据字典例如：url、是否分享等参数
     */
    openWebPage(data) {
        if (this.isIos) {
            this.mxInteraction({ action: 'openWebPage', value: data })
        } else {
            window.MxNative.openWebPage(data)
        }
    },
    uploadDocument(data) {
        if (this.isIos) {
            this.mxInteraction({ action: 'uploadDocument', value: data })
        } else {
            window.MxNative.uploadDocument(data)
        }
    },
    createEmail(data) {
        if (this.isIos) {
            this.mxInteraction({ action: 'createEmail', value: data })
        } else {
            window.MxNative.createEmail(data)
        }
    },
    // 截止2021/11/15 后续不再使用
    async checkVersion() {
        let appVersion, isNewVersion
        if (this.isIos) {
            appVersion = await this.sendMessage('getVersionInfo').catch(err => {
                console.log(err)
            })
            isNewVersion = appVersion > this.NEW_VERSION_IOS
        } else {
            appVersion = window.MxNative['getVersionInfo']
                ? window.MxNative['getVersionInfo']()
                : 0
            isNewVersion = appVersion > this.NEW_VERSION_ANDROID
        }
        console.log('isNewVersion', isNewVersion)
        setItem('isNewVersion', isNewVersion ? '1' : '0')
    },
    /**
     * @param {iOS版本号} iosVersion
     * @param {Android版本号} androidVersion
     * 大于指定版本 就展示内容
     */
    async checkVersionNew(iosVersion, androidVersion) {
        let appVersion, isShowVersionContent
        if (this.isIos) {
            // 获取iOS当前版本
            appVersion = await this.sendMessage('getVersionInfo').catch(err => {
                console.log(err)
            })
            isShowVersionContent = appVersion > iosVersion
        } else {
            // 获取Android当前版本
            appVersion = window.MxNative['getVersionInfo']
                ? window.MxNative['getVersionInfo']()
                : 0
            isShowVersionContent = appVersion > androidVersion
        }
        return isShowVersionContent
    },
    initCtid(data) {
        if (this.isIos) {
            this.mxInteraction({ action: 'initCtid', value: data })
        } else {
            window.MxNative['initCtid'] && window.MxNative.initCtid(data)
        }
    },
    /* Mail */
    openMail(data) {
        if (this.isIos) {
            this.mxInteraction({ action: 'openMail', value: JSON.stringify(data) })
        } else {
            window.MxNative['openMail'] && window.MxNative['openMail'](JSON.stringify(data))
        }
    }
}

window['hybrid'] = hybrid

export default hybrid
