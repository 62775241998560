export function language(state) {
    return state.language
}

export function individualConfigInfo(state) {
    return state.individualConfigInfo
}

export function companie(state) {
    return state.companie
}

// 获取系统下拉框值
export const sysBoxValue = state => state.sysBoxValue

// 系统下拉框值key/Value形式
export const sysBoxValueMap = state => {
    let listShow = {}
    state.sysBoxValue.forEach(element => {
        let obj = {
            dictCode: element.dictCode,
            alias: element.inUse,
            dictName: element.dictName,
            moduleCode: element.moduleCode,
            sortOrder: element.sortOrder,
            id: element.id
        }
        let dictItem = {}
        element.dictItems.forEach(item => {
            dictItem[item.dictItemCode] = item
        })
        obj.dictItem = dictItem
        listShow[element.dictCode] = obj
    })
    return listShow
}

// 获取系统下拉框值
export const cusBoxValue = state => state.cusBoxValue

// 获取钉钉用户信息
export const userInfo = state => state.userInfo

// header标题
export const headerTitle = state => state.headerTitle

// 获取新增表单校验key
export const formKey = state => state.formKey

// 表单关闭是否提示
export const closeYes = state => state.closeYes

// 路由传参
export const routParameters = state => state.routParameters
export const portList = state => state.portList
export const portListShow = state => state.portListShow

// 获取人员列表
export const contactList = state => state.contactList
// 获取人员列表
export const contactValue = state => state.contactValue

export const greyFunctions = state => state.greyFunctions

// 获取系统下拉框值
export const AONIcustSort = state => state.AONIcustSort

// 货币
export const currency = state => state.currency

export const currencyShow = state => state.currencyShow
// 获取域名前缀
export const domain = state => state.domain
// 获取计量单位
export const unitList = state => state.unitList
