import * as types from './mutation-types'
export default {
    [types.SETLANGUAGE]: (state, language) => {
        state.language = language
    },
    [types.SET_INDIVIDUALCONFIGINFO]: (state, data) => {
        state.individualConfigInfo = data
    },
    [types.SET_COMPANIE]: (state, companie) => {
        state.companie = companie
    },
    [types.SET_SYSBOXVALUE](state, sysBoxValue) {
        state.sysBoxValue = sysBoxValue
    },
    [types.SET_CUSBOXVALUE](state, cusBoxValue) {
        state.cusBoxValue = cusBoxValue
    },
    [types.SET_USERINFO](state, userInfo) {
        state.userInfo = userInfo
    },
    [types.SET_HEADERTITLE](state, headerTitle) {
        state.headerTitle = headerTitle
    },
    [types.SET_FORMKEY](state, formKey) { // 获取新增表单校验key
        state.formKey = formKey
    },
    [types.SET_CLOSEYES](state, closeYes) {
        state.closeYes = closeYes
    },
    [types.SET_ROUTPARAMETERS](state, routParameters) {
        state.routParameters = routParameters
    },
    [types.SET_PORTLIST](state, portList) {
        state.portList = portList
    },
    [types.SET_PORTLISTSHOW](state, portListShow) {
        state.portListShow = portListShow
    },
    [types.SET_CONTACTLIST](state, contactList) {
        state.contactList = contactList
    },
    [types.SET_CONTACTVALUE](state, contactValue) {
        state.contactValue = contactValue
    },
    [types.SET_GREYFUNCTIONS](state, greyFunctions) {
        state.greyFunctions = greyFunctions
    },
    [types.SET_CURRENCYSHOW](state, currencyShow) {
        state.currencyShow = currencyShow
    },
    [types.SET_CURRENCY](state, currency) {
        state.currency = currency
    },
    [types.SET_AONICUSTSORT](state, AONIcustSort) {
        state.AONIcustSort = AONIcustSort
    },
    [types.SET_DOMAIN](state, domain) {
        state.domain = domain
    },
    [types.SET_UNITLIST](state, unitList) {
        state.unitList = unitList
    }
}
