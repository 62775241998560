export const SETLANGUAGE = 'setLanguage'

export const SET_INDIVIDUALCONFIGINFO = 'SET_INDIVIDUALCONFIGINFO'

export const SET_COMPANIE = 'SET_COMPANIE'

// 设置系统动态组件下拉框值
export const SET_SYSBOXVALUE = 'SET_SYSBOXVALUE'

// 设置系统动态组件自定义下拉框值
export const SET_CUSBOXVALUE = 'SET_CUSBOXVALUE'

// 钉钉用户信息
export const SET_USERINFO = 'SET_USERINFO'

// 设置标题
export const SET_HEADERTITLE = 'SET_HEADERTITLE'

// 获取新增表单校验key
export const SET_FORMKEY = 'SET_FORMKEY'

// 表单关闭是否提示
export const SET_CLOSEYES = 'SET_CLOSEYES'

// 路由传参
export const SET_ROUTPARAMETERS = 'SET_ROUTPARAMETERS'

export const SET_PORTLIST = 'SET_PORTLIST'
export const SET_PORTLISTSHOW = 'SET_PORTLISTSHOW'

// 公司全部人员下拉框值
export const SET_CONTACTLIST = 'SET_CONTACTLIST'
// 公司全部人员下拉框值
export const SET_CONTACTVALUE = 'SET_CONTACTVALUE'

export const SET_GREYFUNCTIONS = 'SET_GREYFUNCTIONS'

//  奥尼客户归类
export const SET_AONICUSTSORT = 'SET_AONICUSTSORT'

export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_CURRENCYSHOW = 'SET_CURRENCYSHOW'
// 系统后端域名前缀
export const SET_DOMAIN = 'SET_DOMAIN'

export const SET_ISAPP = 'SET_ISAPP'

export const SET_UNITLIST = 'SET_UNITLIST'
